/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Date component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   18.05.21 Sean Flook         WI39345 Use the value directly.
//    005   20.05.21 Sean Flook         WI39345 Undo above changes and added new useEffect for the value changing.
//    006   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    007   25.05.21 Sean Flook         WI39345 Changes to get the Tooltip to display correctly and include required field text if required to tooltip.
//    008   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//    009   15.06.21 Sean Flook         WI39345 Display the toolbar as temporary fix, until v5 components are fully released.
//    010   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    011   12.12.23 Sean Flook                 Fix MUI for React 18
//    012   18.12.23 Sean Flook                 Ensure tooltip is displayed
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import "date-fns";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, parseISO } from "date-fns";
import AAErrorDisplay from "./AAErrorDisplay";
import { tooltipStyle, FormDateInputStyle, formReadOnlyStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/system";

/* #endregion imports */

AADateControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
};

AADateControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  errorText: "",
};

function AADateControl({ label, isEditable, isRequired, helperText, value, errorText, onChange }) {
  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState(parseISO(value));
  const hasError = useRef(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (onChange) onChange(date);
  };

  useEffect(() => {
    hasError.current = !errorText && errorText !== "" && errorText !== null;
    // console.log("DEBUG AADateControl useEffect", hasError.current, errorText);
  }, [errorText]);

  useEffect(() => {
    const isValidDate = (d) => {
      return d && Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d);
    };

    if (isValidDate(value)) setSelectedDate(value);
    else setSelectedDate(parseISO(value));
  }, [value]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
        color: theme.palette.text.secondary,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Grid item xs={4}>
        <Typography variant="body2" color={hasError.current ? "error" : "textPrimary"} align="left">
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEditable ? (
          helperText && helperText.length > 0 ? (
            // <DatePicker
            //   sx={{
            //     marginTop: 0,
            //     backgroundColor: theme.palette.background.paper,
            //   }}
            //   variant="dialog"
            //   format="dd MMMM yyyy"
            //   margin="dense"
            //   error={hasError.current}
            //   value={selectedDate}
            //   initialFocusedDate={selectedDate}
            //   fullWidth
            //   autoOk="true"
            //   size="small"
            //   inputVariant="outlined"
            //   disabled={!isEditable}
            //   required={isRequired}
            //   helperText={hasError.current && errorText}
            //   InputProps={{
            //     classes: {
            //       root: classes.outlinedInput,
            //       focused: classes.outlinedInputFocused,
            //       notchedOutline: classes.notchedOutline,
            //     },
            //   }}
            //   onChange={handleDateChange}
            //   KeyboardButtonProps={{
            //     "aria-label": "change date",
            //   }}
            //   TextFieldComponent={TextFieldWithTooltip}
            // />
            <Tooltip
              title={isRequired ? helperText + " This is a required field." : helperText}
              arrow
              placement="right"
              sx={tooltipStyle}
            >
              <div>
                <DatePicker
                  id={`${label.toLowerCase().replaceAll(" ", "-")}-date-picker`}
                  format="dd MMMM yyyy"
                  disableMaskedInput
                  value={selectedDate}
                  showTodayButton
                  required={isRequired}
                  disabled={!isEditable}
                  slotProps={{
                    textField: {
                      id: `${label.toLowerCase().replaceAll(" ", "-")}-date-picker-textfield`,
                      sx: FormDateInputStyle(hasError.current),
                      variant: "outlined",
                      error: hasError.current,
                      margin: "dense",
                      fullWidth: "true",
                      size: "small",
                    },
                  }}
                  onChange={(newValue) => handleDateChange(newValue)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  aria-labelledby={`${label.toLowerCase().replaceAll(" ", "-")}-label`}
                  aria-describedby={`${label.toLowerCase().replaceAll(" ", "-")}-error`}
                />
              </div>
            </Tooltip>
          ) : (
            // <DatePicker
            // sx={{
            //   marginTop: 0,
            //   backgroundColor: theme.palette.background.paper,
            // }}
            //   variant="dialog"
            //   format="dd MMMM yyyy"
            //   margin="dense"
            //   error={hasError.current}
            //   value={selectedDate}
            //   initialFocusedDate={selectedDate}
            //   fullWidth
            //   size="small"
            //   autoOk="true"
            //   inputVariant="outlined"
            //   disabled={!isEditable}
            //   required={isRequired}
            //   helperText={hasError.current && errorText}
            //   InputProps={{
            //     classes: {
            //       root: classes.outlinedInput,
            //       focused: classes.outlinedInputFocused,
            //       notchedOutline: classes.notchedOutline,
            //     },
            //   }}
            //   onChange={handleDateChange}
            //   KeyboardButtonProps={{
            //     "aria-label": "change date",
            //   }}
            // />
            <DatePicker
              id={`${label.toLowerCase().replaceAll(" ", "-")}-date-picker`}
              format="dd MMMM yyyy"
              disableMaskedInput
              value={selectedDate}
              showTodayButton
              required={isRequired}
              disabled={!isEditable}
              slotProps={{
                textField: {
                  id: `${label.toLowerCase().replaceAll(" ", "-")}-date-picker-textfield`,
                  sx: FormDateInputStyle(hasError.current),
                  variant: "outlined",
                  error: hasError.current,
                  margin: "dense",
                  fullWidth: "true",
                  size: "small",
                },
              }}
              onChange={(newValue) => handleDateChange(newValue)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              aria-labelledby={`${label.toLowerCase().replaceAll(" ", "-")}-label`}
              aria-describedby={`${label.toLowerCase().replaceAll(" ", "-")}-error`}
            />
          )
        ) : (
          selectedDate && (
            <Typography variant="body1" align="left" color="textPrimary" sx={formReadOnlyStyle}>
              {format(selectedDate, "dd MMMM yyyy")}
            </Typography>
          )
        )}
      </Grid>
      <AAErrorDisplay errorText={errorText} />
    </Grid>
  );
}

export default AADateControl;
