/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets ESRI Map component
//
//  Copyright:    © 2021 - 2022 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Changed the OS map we are using to Road_3857.
//    003   10.05.21 Sean Flook         WI39345 Added some debug message to try and fix mapping issue.
//    004   24.05.21 Sean Flook         WI39345 Use the Material UI icons in the zoom widget.
//    005   25.05.21 Sean Flook         WI39345 Upgraded to latest version of the ESRI components.
//    006   01.06.21 Sean Flook         WI39345 Use FeatureLayer so that we can display a popup of the address of the property.
//    007   08.06.21 Sean Flook         WI39345 Removed buttons from popup dialog.
//    008   17.06.21 Sean Flook         WI39345 Use formatted address in the popup and added data type counts.
//    009   18.06.21 Sean Flook         WI39345 Prevent a crash if we do not have an address.
//    010   24.06.21 Sean Flook         WI39345 Changed the OS API Key.
//    011   28.06.21 Sean Flook         WI39345 Do not use a list for the type counts.
//    012   01.07.21 Sean Flook         WI39345 Bold the count information.
//    013   14.01.22 Sean Flook         WI39927 Set the minScale and maxScale for the view.
//    014   25.08.22 Joel Benford       WI40270 Comment re map API key
//    015   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useContext, useEffect, useRef } from "react";
import PropertyContext from "../context/propertyContext";
import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";
import { Box } from "@mui/material";
/* #endregion imports */

function AAEsriMap() {
  const mapRef = useRef();
  const propertyContext = useContext(PropertyContext);

  const apiKey = "5V8IQfTGaj7ET0mPuGIh5cpFpkXtrVYF"; // customer specific API key for MET ONLY, do not use anywhere else
  const serviceUrl = "https://api.os.uk/maps/raster/v1/wmts";
  const baseMappingLayerName = "baseMappingLayer";
  const propertyLayerName = "propertyLayer";

  function addressToTitleCase(str) {
    return !str || str.length === 0
      ? str
      : str.replace(propertyContext.currentProperty.postcode, "").replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) + propertyContext.currentProperty.postcode;
  }

  useEffect(() => {
    // console.log("DEBUG AAEsriMap useEffect", propertyContext.currentProperty);

    const wmtsLayer = new WMTSLayer({
      url: serviceUrl,
      serviceMode: "KVP",
      activeLayer: {
        id: "Road_3857",
      },
      customParameters: {
        key: apiKey,
      },
      id: baseMappingLayerName,
      copyright: "Contains OS data © Crown copyright and database rights " + new Date().getFullYear(),
    });

    const propertySymbol = new CIMSymbol({
      data: {
        type: "CIMSymbolReference",
        symbol: {
          type: "CIMPointSymbol",
          symbolLayers: [
            {
              type: "CIMVectorMarker",
              enable: true,
              anchorPointUnits: "Relative",
              dominantSizeAxis3D: "Y",
              size: 22,
              billboardMode3D: "FaceNearPlane",
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 21,
                ymax: 21,
              },
              markerGraphics: [
                {
                  type: "CIMMarkerGraphic",
                  geometry: {
                    rings: [
                      [
                        [18, 11],
                        [17, 11],
                        [17, 5],
                        [18, 5],
                        [18, 4],
                        [3, 4],
                        [3, 5],
                        [4, 5],
                        [4, 11],
                        [3, 11],
                        [3, 12],
                        [10.5, 18],
                        [18, 12],
                        [18, 11],
                      ],
                      [
                        [6, 11],
                        [6, 8],
                        [9, 8],
                        [9, 11],
                        [6, 11],
                      ],
                      [
                        [12, 5],
                        [15, 5],
                        [15, 11],
                        [12, 11],
                        [12, 5],
                      ],
                    ],
                  },
                  symbol: {
                    type: "CIMPolygonSymbol",
                    symbolLayers: [
                      {
                        type: "CIMSolidStroke",
                        enable: true,
                        capStyle: "Round",
                        joinStyle: "Round",
                        lineStyle3D: "Strip",
                        miterLimit: 10,
                        width: 0,
                        color: [0, 0, 0, 255],
                      },
                      {
                        type: "CIMSolidFill",
                        enable: true,
                        color: [255, 255, 255, 255],
                      },
                    ],
                  },
                },
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true,
              offsetY: 27,
              colorLocked: true,
            },
            {
              type: "CIMVectorMarker",
              enable: true,
              anchorPoint: {
                x: 0,
                y: -0.5,
              },
              anchorPointUnits: "Relative",
              dominantSizeAxis3D: "Y",
              size: 40,
              billboardMode3D: "FaceNearPlane",
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 21,
                ymax: 21,
              },
              markerGraphics: [
                {
                  type: "CIMMarkerGraphic",
                  geometry: {
                    rings: [
                      [
                        [17.17, 14.33],
                        [16.97, 12.96],
                        [16.38, 11.37],
                        [12.16, 3.98],
                        [11.2, 1.94],
                        [10.5, 0],
                        [9.8, 1.96],
                        [8.84, 4.02],
                        [4.61, 11.41],
                        [4.02, 12.98],
                        [3.83, 14.33],
                        [3.96, 15.63],
                        [4.34, 16.88],
                        [4.95, 18.03],
                        [5.78, 19.04],
                        [6.8, 19.88],
                        [7.95, 20.49],
                        [9.2, 20.87],
                        [10.5, 21],
                        [11.8, 20.87],
                        [13.05, 20.5],
                        [14.2, 19.88],
                        [15.22, 19.05],
                        [16.05, 18.03],
                        [16.66, 16.88],
                        [17.04, 15.63],
                        [17.17, 14.33],
                      ],
                    ],
                  },
                  symbol: {
                    type: "CIMPolygonSymbol",
                    symbolLayers: [
                      {
                        type: "CIMSolidStroke",
                        enable: true,
                        capStyle: "Round",
                        joinStyle: "Round",
                        lineStyle3D: "Strip",
                        miterLimit: 10,
                        width: 0,
                        color: [50, 48, 151, 255],
                      },
                      {
                        type: "CIMSolidFill",
                        enable: true,
                        color: [50, 48, 151, 255],
                      },
                    ],
                  },
                },
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true,
              colorLocked: true,
            },
          ],
        },
      },
    });

    const propertyFeatures = [
      {
        geometry: {
          type: "point",
          x: propertyContext.currentProperty.longitude,
          y: propertyContext.currentProperty.latitude,
          symbol: propertySymbol,
        },
        attributes: {
          ObjectID: 1,
          UPRN: propertyContext.currentProperty.uprn,
          Address: addressToTitleCase(propertyContext.currentProperty.formattedAddress),
          RiskCount: propertyContext.currentProperty.riskCount,
          PeopleCount: propertyContext.currentProperty.peopleCount,
          LocationCount: propertyContext.currentProperty.locationCount,
          MiscellaneousCount: propertyContext.currentProperty.miscellaneousCount,
        },
      },
    ];

    const propertyLayer = new FeatureLayer({
      id: propertyLayerName,
      copyright: "© Copyright Aligned Assets Ltd. " + new Date().getFullYear(),
      source: propertyFeatures,
      fields: [
        {
          name: "ObjectID",
          alias: "ObjectID",
          type: "oid",
        },
        {
          name: "UPRN",
          alias: "UPRN",
          type: "string",
        },
        {
          name: "Address",
          alias: "Address",
          type: "string",
        },
        {
          name: "RiskCount",
          alias: "Risk",
          type: "string",
        },
        {
          name: "PeopleCount",
          alias: "People",
          type: "string",
        },
        {
          name: "LocationCount",
          alias: "Location",
          type: "string",
        },
        {
          name: "MiscellaneousCount",
          alias: "Miscellaneous",
          type: "string",
        },
      ],
      objectIdField: "ObjectID",
      popupTemplate: {
        title: "{UPRN}",
        content:
          "<p>{Address}</p><strong>Risk: {RiskCount}</strong><br/><strong>People: {PeopleCount}</strong><br/><strong>Location: {LocationCount}</strong><br/><strong>Miscellaneous: {MiscellaneousCount}</strong>",
      },
      renderer: {
        type: "simple",
        symbol: propertySymbol,
      },
    });

    let map = new Map({ layers: [wmtsLayer, propertyLayer] });

    let view = new MapView({
      container: mapRef.current,
      map: map,
      center: [propertyContext.currentProperty.longitude, propertyContext.currentProperty.latitude],
      scale: 1250,
      constraints: {
        minScale: 1000,
        maxScale: 50000,
      },
      popup: {
        autoOpenEnabled: false,
        collapseEnabled: false,
        dockOptions: {
          buttonEnabled: false,
        },
        visibleElements: {
          closeButton: false,
        },
        viewModel: {
          highlightEnabled: false,
          includeDefaultActions: false,
        },
      },
    });

    view.on("pointer-move", function (event) {
      view.hitTest(event).then(function (response) {
        if (response.results.length) {
          let graphic = response.results.filter(function (result) {
            // check if the graphic belongs to the layer of interest
            return result.graphic.layer === propertyLayer;
          })[0].graphic;
          view.popup.open({
            location: graphic.geometry.centroid,
            features: [graphic],
          });
        } else {
          view.popup.close();
        }
      });
    });

    var scaleBar = new ScaleBar({
      view: view,
      unit: "dual", // The scale bar displays both metric and non-metric units.
    });

    view.ui.add(scaleBar, {
      position: "bottom-left",
    });

    view.when((_) => {
      const plus = document.getElementsByClassName("esri-icon-plus");
      if (plus && plus.length === 1) plus[0].classList = "mapPlusIcon";

      const minus = document.getElementsByClassName("esri-icon-minus");
      if (minus && minus.length === 1) minus[0].classList = "mapMinusIcon";
    });

    return () => {
      if (view) {
        // destroy the map view
        view.destroy();
      }
    };
  });

  return (
    <Box
      sx={{
        flexGrow: 0,
        height: "92vh",
      }}
      ref={mapRef}
    />
  );
}

export default AAEsriMap;
