/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Number component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    005   25.05.21 Sean Flook         WI39345 Include required field text if required to tooltip.
//    006   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//    007   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Typography, Tooltip } from "@mui/material";
import { formRowStyle, FormInputStyle } from "../utils/AAStyles";
/* #endregion imports */

AANumberControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.number,
  errorText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AANumberControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  errorText: "",
};

function AANumberControl({ label, isEditable, isRequired, helperText, value, errorText, onChange }) {
  const hasError = useRef(false);

  useEffect(() => {
    hasError.current = errorText !== "" && errorText !== null;
  }, [errorText]);

  return (
    <Grid container justifyContent="flex-start" alignItems="center" sx={formRowStyle}>
      <Grid item xs={4}>
        <Typography
          variant="body2"
          color={hasError.current ? "error" : isEditable ? "textPrimary" : "initial"}
          align="left"
        >
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {helperText && helperText.length > 0 ? (
          <Tooltip title={isRequired ? helperText + " This is a required field." : helperText} arrow placement="right">
            <TextField
              sx={FormInputStyle(hasError.current)}
              type="number"
              error={hasError.current}
              fullWidth
              disabled={!isEditable}
              required={isRequired}
              variant="outlined"
              margin="dense"
              size="small"
              helperText={hasError.current && errorText}
              value={value}
              onChange={onChange}
            />
          </Tooltip>
        ) : (
          <TextField
            sx={FormInputStyle(hasError.current)}
            type="number"
            error={hasError.current}
            fullWidth
            disabled={!isEditable}
            required={isRequired}
            variant="outlined"
            margin="dense"
            size="small"
            helperText={hasError.current && errorText}
            value={value}
            onChange={onChange}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AANumberControl;
