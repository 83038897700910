/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Error Display component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   22.04.21 Sean Flook         WI39345 Removed extra label as not required any more.
//    003   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    004   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React from "react";
import { Typography, Grid } from "@mui/material";

/* #endregion imports */

const AAErrorDisplay = ({ errorText }) => {
  return errorText !== null ? (
    <React.Fragment>
      <Grid item xs={4} justifyContent="flex-start"></Grid>
      <Grid item xs={8} justifyContent="flex-start">
        <Typography variant="caption" color="error" align="left">
          {errorText}
        </Typography>
      </Grid>
    </React.Fragment>
  ) : (
    ""
  );
};

export default AAErrorDisplay;
