/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Help Drawer component
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   02.06.21 Sean Flook         WI39345 Tidied up the content.
//    003   08.06.21 Sean Flook         WI39345 Modified after UX review.
//    004   16.06.21 Sean Flook         WI39345 Removed divider, corrected spelling and set subject line for emails.
//    005   18.08.22 Joel Benford       WI39946 Add help link to configured URL
//    006   28.09.22 Joel Benford       WI40265 Switch to hosting help in react public folder
//    007   09.12.22 Joel Benford       WI39945 Display selected version info from API
//    008   09.12.22 Joel Benford       WI39945 Handle missing sub-objects if API is down
//    009   12.12.22 Joel Benford       WI39945 ...also for Epoch versions
//    010   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    011   12.12.23 Sean Flook                 Fix MUI for React 18
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
import PropTypes from "prop-types";

import MetadataContext from "../context/metadataContext";

import { Drawer, Typography, Link, Box, Grid } from "@mui/material";
import AAActionButton from "./AAActionButton";

import HelpIcon from "@mui/icons-material/HelpOutlined";
import ForumIcon from "@mui/icons-material/ForumOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import CallIcon from "@mui/icons-material/CallOutlined";

import { helpBarWidth, helpRowStyle, helpIconStyle, helpTextStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/system";
/* #endregion imports */

AAHelpDrawer.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
};

function AAHelpDrawer(props) {
  const theme = useTheme();

  const metadataContext = useContext(MetadataContext);

  return (
    <Drawer
      sx={{
        width: helpBarWidth,
        anchor: "right",
        flexShrink: 0,
      }}
      variant="persistent"
      anchor="right"
      open={props.open}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: "flex-start",
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h6"
              noWrap
              sx={{
                color: "#797979",
              }}
            >
              Help and support
            </Typography>
          </Grid>
          <Grid item>
            <AAActionButton
              variant="close"
              tooltipTitle="Close help"
              tooltipPlacement="left"
              onClick={props.handleDrawerClose}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          paddingLeft: theme.spacing(2),
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid item xs={1}>
            <HelpIcon sx={helpIconStyle} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Online help
              <br />
              <Link
                align={"left"}
                href={
                  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                    ? process.env.PUBLIC_URL + "/help/"
                    : "/help/"
                }
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Help documents for XDM Manager
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid item xs={1}>
            <ForumIcon sx={helpIconStyle} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Customer portal
              <br />
              <Link
                align={"left"}
                href="https://portal.aligned-assets.co.uk"
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Browse the Knowledge Base or search forums
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid item xs={1}>
            <img src="/images/SupportAgent.svg" alt="" sx={helpIconStyle} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              Contact support <br />
              <Link
                align={"left"}
                href="mailto:support@aligned-assets.co.uk?subject=Support enquiry raised from XDM Manager Help"
                variant="body2"
              >
                Raise a case with our support team
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid item xs={1}>
            <EmailIcon sx={helpIconStyle} />
          </Grid>
          <Grid item>
            <Typography align="left" variant="subtitle2">
              General enquiries <br />
              <Link
                align={"left"}
                href="mailto:solutions@aligned-assets.co.uk?subject=Solutions enquiry raised from XDM Manager Help"
                variant="body2"
              >
                solutions@aligned-assets.co.uk
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid item xs={1}>
            <CallIcon sx={helpIconStyle} />
          </Grid>
          <Grid>
            <Grid item>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                +44 (0) 1483 717 950
              </Typography>
            </Grid>
            <Grid item xs={12} sx={helpRowStyle}>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                Aligned Assets
              </Typography>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                Unit 5 Woking 8
              </Typography>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                Forsyth Road
              </Typography>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                Woking
              </Typography>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                Surrey
              </Typography>
              <Typography align={"left"} variant="body2" sx={helpTextStyle}>
                GU21 5SB
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={helpRowStyle}>
          <Grid>
            <Grid item>
              <Typography align="left" variant="subtitle1">
                Metadata information
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                GUI version: {metadataContext.metadata.versionInfo.guiVersion || "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                XDM cloud API: {metadataContext.metadata.versionInfo.xdmapiVer || "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                XDM database: {metadataContext.metadata.versionInfo.xdmdbVer || "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                XDM search index dll: {metadataContext.metadata.versionInfo.searchIndexDllVer || "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                XDM search lib dll: {metadataContext.metadata.versionInfo.searchLibDllVer || "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                XDM search index built:{" "}
                {(metadataContext.metadata.versionInfo.xdmIndexMeta &&
                  metadataContext.metadata.versionInfo.xdmIndexMeta.builton) ||
                  "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                Bluelight database:{" "}
                {(metadataContext.metadata.versionInfo.addressIndexMeta &&
                  metadataContext.metadata.versionInfo.addressIndexMeta.bluelightversion) ||
                  "unknown"}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="left" variant="body2">
                Epoch:{" "}
                {(metadataContext.metadata.versionInfo.addressIndexMeta &&
                  metadataContext.metadata.versionInfo.addressIndexMeta.epochversion) ||
                  "unknown"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
}

export default AAHelpDrawer;
