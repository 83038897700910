/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets XDM Data List Item component
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   10.05.21 Sean Flook         WI39345 Corrected icons.
//    004   14.05.21 Sean Flook         WI39345 Only display the add icon on hover and set colour for dates if required.
//    005   19.05.21 Sean Flook         WI39345 Various changes to the display of items in the list.
//    006   19.05.21 Sean Flook         WI39345 Fixed colour for expired dates.
//    007   20.05.21 Sean Flook         WI39345 Only change the colour on the dates.
//    008   24.05.21 Sean Flook         WI39345 Various changes to the design of the items.
//    009   01.06.21 Sean Flook         WI39345 Highlight icons when the cursor is hovering over them.
//    010   11.06.21 Sean Flook         WI39345 Changes required to expand and collapse all items.
//    011   17.06.21 Sean Flook         WI39345 Use rounded avatars rather than circles so they do not look strange if we have to expand them.
//    012   23.06.21 Sean Flook         WI39345 Handle when we do not have a date.
//    013   28.06.21 Sean Flook         WI39345 If we have no expiry date or review date display "n/a". Set the autoFocus to ensure the last viewed item is viewable.
//    014   01.07.21 Sean Flook         WI39345 Added Last updated and sorted the list on this field.
//    015   10.08.22 Joel Benford       WI39932 Show hint to help in record identification
//    016   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    017   12.12.23 Sean Flook                 Fix MUI for React 18
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useState, useEffect, Fragment } from "react";
import { useListState, useListStateUpdate } from "../context/DataListContext";

import { format, parseISO } from "date-fns";
import { FormatDate } from "../components/tables/DashboardFormattingUtils";

import {
  Stack,
  List,
  Collapse,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  ListItemButton,
} from "@mui/material";
import AAActionButton from "./../components/AAActionButton";

import { RiskIcon, PeopleIcon, LocationIcon, MiscellaneousIcon } from "./AADataTypeIcons";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import WarningIcon from "@mui/icons-material/Warning";

import { red, grey } from "@mui/material/colors";
import { listTypeIconStyle, listDateStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/system";

/* #endregion imports */

const AAXDMDataListItem = ({ title, itemIcon, data, editable, itemState, itemSelect, itemAdd }) => {
  const theme = useTheme();

  const getOpen = useListState();
  const toggleOpen = useListStateUpdate();

  const [open, setOpen] = useState(getOpen(title));

  const [itemSelected, setItemSelected] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(data ? data.selectedItem : null);

  const [riskHover, setRiskHover] = useState(false);
  const [peopleHover, setPeopleHover] = useState(false);
  const [locationHover, setLocationHover] = useState(false);
  const [miscellaneousHover, setMiscellaneousHover] = useState(false);

  const nullDate = "__ ___ ____";

  const handleOpenClose = () => {
    setOpen(!open);
    toggleOpen(title);
  };

  const showListIcon = (icon) => {
    switch (icon) {
      case "Risk":
        return <RiskIcon hover={riskHover} />;
      case "People":
        return <PeopleIcon hover={peopleHover} />;
      case "Location":
        return <LocationIcon hover={locationHover} />;
      default:
        return <MiscellaneousIcon hover={miscellaneousHover} />;
    }
  };

  const handleMouseEnter = () => {
    SetHover(true);
    setItemSelected(true);
  };

  const handleMouseLeave = () => {
    SetHover(false);
    setItemSelected(false);
  };

  const handleMouseEnterRecord = (event) => {
    // console.log("DEBUG handleMouseEnterRecord", event.target.id);
    setSelectedRecord(event.target.id);
  };

  const handleMouseLeaveRecord = () => {
    setSelectedRecord(null);
  };

  function SetHover(hover) {
    if (itemIcon && itemIcon.length > 0) {
      switch (itemIcon) {
        case "Risk":
          setRiskHover(hover);
          break;
        case "People":
          setPeopleHover(hover);
          break;
        case "Location":
          setLocationHover(hover);
          break;
        default:
          setMiscellaneousHover(hover);
          break;
      }
    }
  }

  function ShowWarningIcon(state) {
    switch (state) {
      case "expired":
      case "reviewRequired":
        return (
          <WarningIcon
            sx={{
              color: red[600],
              width: theme.spacing(2.5),
              height: theme.spacing(2.5),
              mb: theme.spacing(0.75),
              mr: theme.spacing(1),
            }}
          />
        );
      default:
        return null;
    }
  }

  function GetAvatarStyle(data) {
    if (!data) return null;

    let avatarColour = theme.palette.getContrastText(red[600]);
    let avatarBackgroundColour = red[600];

    if (data.filter((x) => x.state !== "fine").length === 0) {
      avatarColour = "#535353";
      avatarBackgroundColour = "#d7d7d7";
    }

    if (data && data.length > 99)
      return {
        width: "32px",
        height: "16px",
        pt: "1.4px",
        borderRadius: "18px",
        fontFamily: "Open Sans",
        color: avatarColour,
        backgroundColor: avatarBackgroundColour,
      };
    else if (data && data.length > 9)
      return {
        width: "24px",
        height: "16px",
        pt: "1.4px",
        borderRadius: "18px",
        fontFamily: "Open Sans",
        color: avatarColour,
        backgroundColor: avatarBackgroundColour,
      };
    else
      return {
        width: "16px",
        height: "16px",
        pt: "1.4px",
        borderRadius: "18px",
        fontFamily: "Open Sans",
        color: avatarColour,
        backgroundColor: avatarBackgroundColour,
      };
  }

  // console.log("DEBUG AAXDMDataListItem", selectedRecord, data);

  useEffect(() => {
    switch (itemState) {
      case "expanded":
        setOpen(true);
        break;

      case "collapsed":
        setOpen(false);
        break;

      default:
        // Do nothing here
        break;
    }
  }, [itemState]);

  useEffect(() => {
    setSelectedRecord(data ? data.selected : null);
  }, [data]);

  return (
    <Fragment>
      <ListItemButton
        divider
        dense
        disableGutters
        sx={{
          height: "50px",
        }}
        selected={itemSelected}
        onClick={handleOpenClose}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {open ? <ExpandMore /> : <ChevronRight />}
        {itemIcon && itemIcon.length > 0 ? (
          <ListItemIcon sx={listTypeIconStyle}>{showListIcon(itemIcon)}</ListItemIcon>
        ) : (
          ""
        )}
        <ListItemText
          primary={
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography variant="subtitle1">{`${title ? title : "No title"}`}</Typography>
              <Avatar variant="circular" sx={GetAvatarStyle(data)}>
                <Typography variant="caption">
                  <strong>{`${data ? data.length : 0}`}</strong>
                </Typography>
              </Avatar>
            </Stack>
          }
          sx={
            itemIcon
              ? null
              : {
                  pl: theme.spacing(4),
                }
          }
        />
        <ListItemAvatar sx={listTypeIconStyle}>
          {(title !== "Miscellaneous" || editable) && itemSelected && (
            <AAActionButton
              variant="add"
              tooltipTitle={`Add ${title} record`}
              tooltipPlacement="right"
              onClick={() => itemAdd(data ? data.length : 0)}
            />
          )}
        </ListItemAvatar>
      </ListItemButton>

      <Collapse in={open} timeout="auto">
        {data &&
          data
            .slice()
            .sort(function (a, b) {
              return new Date(b.lastUpdated) - new Date(a.lastUpdated);
            })
            .map((d, index) => (
              <List component="div" disablePadding dense key={`key_${index}`}>
                <ListItemButton
                  id={d.pkId.toString()}
                  alignItems="flex-start"
                  dense
                  disableGutters
                  autoFocus={d.selectedItem && d.selectedItem >= 0 && d.selectedItem.toString() === d.pkId.toString()}
                  selected={selectedRecord && selectedRecord >= 0 && selectedRecord.toString() === d.pkId.toString()}
                  sx={{
                    paddingLeft: theme.spacing(7),
                    backgroundColor: grey[100],
                    "&:hover": {
                      backgroundColor: "#00AEEF1A",
                      color: "#2A6EBB",
                    },
                  }}
                  onClick={() => itemSelect(d.pkId, index, data.length)}
                  onMouseEnter={handleMouseEnterRecord}
                  onMouseLeave={handleMouseLeaveRecord}
                >
                  <ListItemAvatar sx={listTypeIconStyle}>
                    <Avatar
                      variant="rounded"
                      alt={d.avatarText}
                      src={d.avatarIcon}
                      sx={{
                        width: theme.spacing(3),
                        height: theme.spacing(3),
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Typography variant="body1">{`${d.displayText}`}</Typography>
                        <Typography variant="caption">{d.hint}</Typography>
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                          <Stack direction="row" justifyContent="flex-start" alignItems="flex-end">
                            <TextField
                              variant="standard"
                              label="Review"
                              sx={listDateStyle}
                              size="small"
                              value={d.reviewDate ? format(parseISO(d.reviewDate), "dd MMM yyyy") : nullDate}
                              margin="dense"
                              InputProps={{
                                size: "30px",
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                  color: d.reviewDate && d.state && d.state !== "fine" && "red",
                                },
                              }}
                            />
                            <TextField
                              variant="standard"
                              label="Expiry"
                              sx={listDateStyle}
                              size="small"
                              value={d.expiryDate ? format(parseISO(d.expiryDate), "dd MMM yyyy") : nullDate}
                              margin="dense"
                              InputProps={{
                                size: "30px",
                                disableUnderline: true,
                                readOnly: true,
                                style: {
                                  color: d.expiryDate && d.state && d.state === "expired" && "red",
                                },
                              }}
                            />
                            <TextField
                              variant="standard"
                              label="Last updated"
                              sx={listDateStyle}
                              size="small"
                              value={d.lastUpdated ? FormatDate(d.lastUpdated) : nullDate}
                              margin="dense"
                              InputProps={{
                                size: "30px",
                                disableUnderline: true,
                                readOnly: true,
                              }}
                            />
                          </Stack>
                          {d.state && d.state !== "fine" && ShowWarningIcon(d.state)}
                        </Stack>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </List>
            ))}
      </Collapse>
    </Fragment>
  );
};

export default AAXDMDataListItem;
