//#region header */
/**************************************************************************************************
//
//  Description: Login form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   24.06.21 Joel Benford        WI39518 Initial Revision.
//    002   10.08.21 Joel Benford        WI39518 Interim check-in for new SymphonySecurity API
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import {
        Box,
        FormGroup,
        FormControl,
        FormLabel,
        Button,
        InputLabel,
        Input,
        Typography,
} from "@mui/material";
import { loginStyle, dialogButtonStyle } from "../utils/AAStyles";
//import Typography from "@material-ui/core/Typography";
//import useStyles from "../utils/AAStyles";
//import { composeClasses } from "@material-ui/data-grid";
/* #endregion imports */

const AuthenticateForm = ({ submitHandler, message }) => {
  //const classes = useStyles();
   const [authenticateCode, setAuthenticateCode] = useState('');

  const handleChange = (e) => setAuthenticateCode(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitHandler(authenticateCode);
  };

  return (
      <Box sx={loginStyle}>
      <form onSubmit={handleSubmit}>
        <FormLabel>Authenticate Login</FormLabel>
        <FormGroup>
          <FormControl>
            <InputLabel htmlFor="email">Please enter the Authenticate code emailed to you</InputLabel>
            <Input
              type="text"
              id="code"
              value={authenticateCode}
              onChange={handleChange}
            />
            </FormControl>

          <Button
            type="submit"
            variant="outlined"
            color="primary"
            sx={dialogButtonStyle}>
          >
            Authenticate
          </Button>
        </FormGroup>
          </form>
          <Typography>{message}</Typography>
    </Box>
  );
};

export default AuthenticateForm;
