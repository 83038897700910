//#region header */
/**************************************************************************************************
//
//  Description: Login Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001           Peter Bryden                    Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HomeRoute } from "../PageRouting";
import { SymphonyUserContext } from "../context/symphonyUserContext";
import MetadataContext from "../context/metadataContext";
import * as securityApi from "../services/symphonySecurityApi";
import * as metadataService from "../services/metadataService";
import { GetAuthority, GetTemplate } from "../configuration/AAConfig";
import LoginForm from "../forms/LoginForm";
import AuthenticateForm from "../forms/AuthenticateForm";
import AuthorityCodes from "../data/AuthorityCodes";
import { Button, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { loginStyle } from "../utils/AAStyles";
/* #endregion imports */

const LoginPageWithMFA = (props) => {

    const navigate = useNavigate();   

    const [authority, setAuthority] = useState({
        id: null,
        authority: null,
        template: null,
    });

    const { currentToken, currentUser, currentTokenExpiry, authenticateId, logoff, showAuthenticate, userError, updateUserError, updateLoginDetails, updateAuthenticateId} = useContext(SymphonyUserContext);
    const metadataContext = useContext(MetadataContext);

  const handleSubmitLogin = async (auditname, password) => {

    updateUserError("logging in...");

    const loginResult = await securityApi.authority.login(auditname, password, updateUserError, logoff);

      if (!loginResult) {
          updateUserError("Unable to login with the supplied credentials.");
      }
      else {
          updateUserError(null);        
          updateAuthenticateId(loginResult.authorizeId);
      }
   };


    const HandleSubmitAuthenticate = async (authenticateCode) => {

        const authenticateResult = await securityApi.authority.MFAAuthenticate(authenticateId, authenticateCode);

        if (!authenticateResult) {
            updateUserError("Authorize Code failed");
            return;
        }

        updateLoginDetails(authenticateResult);

        const newMetadata = await metadataService.version.updateFromApi(
            currentToken,
            metadataContext.metadata
        );

        metadataContext.setMetadata(newMetadata);

    };

    const handleLogout = () => logoff(true);

  const loadHomepage = () => {
    navigate(HomeRoute);
  };


  useEffect(() => {
    const getAuthority = async () => {
      if (!authority.id) {
        const detrCode = Number(await GetAuthority());
        const matched = AuthorityCodes.find((a) => a.id === detrCode);
        setAuthority({
          id: detrCode,
          authority: matched
            ? matched.authority
            : "DESCRIPTION NOT FOUND IN AuthorityCodes.js",
          template: await GetTemplate(),
        });
      }
    };
    getAuthority();
  }, [authority]);

    if (currentUser) {
        return (
            <Box sx={loginStyle}>
                <Typography variant="h5">Current login:</Typography>
                <Typography>Token expiry: {currentTokenExpiry}</Typography>

                <Typography variant="h5">Current user:</Typography>
                <Typography>First name: {currentUser.firstName}</Typography>
                <Typography>Last name: {currentUser.lastName}</Typography>
                <Typography>Audit name: {currentUser.auditName}</Typography>
                <Typography>Email: {currentUser.email}</Typography>
                <Typography>Organisation: {currentUser.organisation}</Typography>
                <Typography>Has rights: {currentUser.rights.map((r) => `${r}, `)}</Typography>

                <Typography variant="h5">Configuration:</Typography>
                <Typography>
                    Authority: {authority.id} ({authority.authority})
                </Typography>
                <Typography>Template: {authority.template}</Typography>

                <Button variant="outlined" onClick={loadHomepage}>
                    Homepage
                </Button>
                <Button variant="outlined" onClick={handleLogout}>
                    Logout
                </Button>
            </Box>
        );
    } 
    else if (showAuthenticate)
    {
        return <AuthenticateForm submitHandler={HandleSubmitAuthenticate} message={userError} />;
    }
    else
    {
        return <LoginForm submitHandler={handleSubmitLogin} message={userError} />;
    }
};

export default LoginPageWithMFA;
