//#region header */
/**************************************************************************************************
//
//  Description: Home Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   07.05.21 Peter Bryden               Updated UseEffect to prevent page failing if data isn't loaded
//    004   10.05.21 Sean Flook         WI39345 Small changes.
//    005   12.05.21 Sean Flook         WI39345 Added code to display the overdue chart correctly.
//    006   12.05.21 Sean Flook         WI39345 Removed commented out code.
//    007   14.05.21 Sean Flook         WI39345 Changes required to get components to display correctly on page.
//    008   20.05.21 Sean Flook         WI39345 Removed overdue and added miscellaneous chart.
//    009   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    010   30.09.21 Peter Bryden               updated useEffect to stop multiple calls..
//	  011	  09.03.22 Peter Bryden		   WI40103	Added in Symphony API Security Authentication
//    012   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, { useState, useEffect, useContext } from "react";

import { Box, Grid, Alert } from "@mui/material";
import {
  GetDataTypeCounts,
  GetCategoryCounts,
  GetExpiredRecords,
  GetReviewRecords,
  GetUpdatedRecords,
  authBearerHeader,
} from "../configuration/AAConfig";
import DashboardChartsForm from "../forms/DashboardChartsForm";
import DashboardTablesForm from "../forms/DashboardTablesForm";
import { SymphonyUserContext } from "../context/symphonyUserContext";
import { homePageStyle } from "../utils/AAStyles";

/* #endregion imports */

function Homepage() {
  const [apiURL, setApiURL] = useState();
  const [allCount, setAllCount] = useState();
  const [countsLoaded, setCountsLoaded] = useState();
  const [dataLoaded, setDataLoaded] = useState();
  const [riskCount, setRiskCount] = useState();
  const [peopleCount, setPeopleCount] = useState();
  const [locationCount, setLocationCount] = useState();
  const [miscellaneousCount, setMiscellaneousCount] = useState();
  const [expiredRecords, setExpiredRecords] = useState();
  const [reviewRecords, setReviewRecords] = useState();
  const [updatedRecords, setUpdatedRecords] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const { currentToken, logoff } = useContext(SymphonyUserContext);



    const GetError = async (res) => {
        console.log("GetError res ", res);
        const responseText = await res.text().then((err) => { return (err ? err : ""); });
        try {
            const jsonText = JSON.parse(responseText);
            return JSON.stringify(jsonText.errors ?? jsonText);
        }
        catch (err) {
            return responseText;
        }
    };


   async function FetchData(apiUrl, isJsonContent, errorHandler,) {
       if (apiUrl) {
           const retVal = await fetch(`${apiUrl.url}`, {
               cache: "no-cache",
               method: apiUrl.type,
               mode: apiUrl.mode,
               crossDomain: true,
               headers: authBearerHeader(currentToken, isJsonContent),
               body: (apiUrl.body) ? JSON.stringify(apiUrl.body) : null,
           })
               .then((res) => (res.ok ? res : Promise.reject(res)))
               .then((res) => res && res.text())
               .then((txtVal) => {
                   try {
                       
                       if (txtVal) {
                           const jsonVal = JSON.parse(txtVal);
                           return jsonVal;
                       }
                   }
                   catch (err) {
                       return txtVal;
                   }
                })
               .then((result) => {
                   //console.log("fetchdata result ",result );
                    return result;
                })
                .catch(async (error) => {

                    const errText = await GetError(error);
                    console.log("fetchdata error ", errText);

                    switch (error.status) {
                        case 400:
                            errorHandler && errorHandler(errText.length > 0 ? `An error occurred : ${errText}` : `An error occurred while process this request`);
                            break;

                        case 401:
                            logoff(true);
                            errorHandler && errorHandler("Your credentials have expired, please login again.");
                            break;

                        case 403:
                            errorHandler && errorHandler("You do not have the correct admin rights to change this user.");
                            break;

                        default:
                            errorHandler && errorHandler(`error (${error.status}) has occurred, please report to support.`);
                            break;
                    }
                    //return error;
                    return [];
                });

            return retVal;
        }
        else {
            errorHandler("No urlData provided for fetch");
            return null;
        }
    }

    //data loading
    useEffect(() => { 
        const finishedcountsLoading = !!allCount &&
            !!riskCount &&
            !!peopleCount &&
            !!locationCount &&
            !!miscellaneousCount;

        if (finishedcountsLoading && !countsLoaded)
            setCountsLoaded(true);

    }, [allCount, riskCount, peopleCount, locationCount, miscellaneousCount, countsLoaded])

    //counts loading
    useEffect(() => {
        const finisheddataLoading =
            (expiredRecords && expiredRecords.length >= 0) &&
            (reviewRecords && reviewRecords.length >= 0) &&
            (updatedRecords && updatedRecords.length >= 0);


        if (finisheddataLoading && countsLoaded && !dataLoaded)
            setDataLoaded(true);

    }, [expiredRecords, reviewRecords, updatedRecords, dataLoaded, countsLoaded])
    
    //apiUrl
  useEffect(() => {
    async function SetUpApi() {
      const allCountUrl = await GetDataTypeCounts();
      const risk_categoryCountUrl = await GetCategoryCounts("risk");
      const people_categoryCountUrl = await GetCategoryCounts("people");
      const location_categoryCountUrl = await GetCategoryCounts("location");
      const misc_categoryCountUrl = await GetCategoryCounts("miscellaneous");
      const expiredRecordsUrl = await GetExpiredRecords();
      const reviewRecordsUrl = await GetReviewRecords();
      const updatedRecordsUrl = await GetUpdatedRecords();

      setApiURL({
        allCount: allCountUrl,
        categoryCount: risk_categoryCountUrl,
        people_categoryCount: people_categoryCountUrl,
        location_categoryCount: location_categoryCountUrl,
        misc_categoryCount: misc_categoryCountUrl,
        expiredRecords: expiredRecordsUrl,
        reviewRecords: reviewRecordsUrl,
        updatedRecords: updatedRecordsUrl,
      });
    }

    if (!apiURL) SetUpApi();
  }, [apiURL]);

  //Fetch AllCount  
  useEffect(() => {
    async function FetchAllCount()
    {
        if (apiURL && apiURL.allCount)
        {
            const  api_url = apiURL.allCount;
            const fetchedResult = await FetchData(api_url, false, setErrorMsg);
            setAllCount(fetchedResult);
        }
    }   

    if (!allCount && apiURL) FetchAllCount();

    return () => { };
  }, [apiURL, allCount, currentToken]);

  //Fetch RiskCount
  useEffect(() => {
    async function FetchRiskCount()
    {
        if (apiURL && apiURL.categoryCount)
        {
            const api_url = apiURL.categoryCount;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setRiskCount(fetchedResult);
        }
    }

    if (!riskCount && apiURL) FetchRiskCount();
  }, [apiURL, riskCount, currentToken]);

  //Fetch PeopleCount
  useEffect(() => {
    async function FetchPeopleCount()
    {
        if (apiURL && apiURL.people_categoryCount)
        {
            const api_url = apiURL.people_categoryCount;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setPeopleCount(fetchedResult);
        }
    }

    if (!peopleCount && apiURL)
        FetchPeopleCount();
  }, [apiURL,peopleCount, currentToken]);

  //Fetch LocationCount
  useEffect(() => {
    async function FetchLocationCount()
    {
        if (apiURL && apiURL.location_categoryCount)
        {
            const api_url = apiURL.location_categoryCount;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setLocationCount(fetchedResult);
        }
    }

    if (!locationCount && apiURL)
        FetchLocationCount();
  }, [apiURL, locationCount, currentToken]);

  //Fetch MiscellaneousCount
    useEffect(() =>
    {
        async function FetchMiscellaneousCount()
        {
            if (apiURL && apiURL.misc_categoryCount)
            {
                const api_url = apiURL.misc_categoryCount;
                const fetchedResult = await FetchData(api_url, true, setErrorMsg);
                setMiscellaneousCount(fetchedResult);
            }
        }

        if (!miscellaneousCount && apiURL) FetchMiscellaneousCount();
  }, [apiURL, miscellaneousCount, currentToken]);

  //Fetch ExpiredCount
  useEffect(() => {
    async function FetchExpiredCount()
    {
        if (apiURL && apiURL.expiredRecords)
        {
            const api_url = apiURL.expiredRecords;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setExpiredRecords(fetchedResult);
        }
    }

    if (!expiredRecords && countsLoaded && apiURL)
        FetchExpiredCount();
  }, [apiURL, expiredRecords, currentToken, countsLoaded]);

  //Fetch ReviewCount
  useEffect(() => {
    async function FetchReviewCount()
    {
        if (apiURL && apiURL.reviewRecords)
        {
            const api_url = apiURL.reviewRecords;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setReviewRecords(fetchedResult, currentToken);
        }
    }

    if (!reviewRecords && countsLoaded && apiURL) FetchReviewCount();
  }, [apiURL, reviewRecords, currentToken, countsLoaded]);

  //Fetch UpdatedCount
  useEffect(() => {
    async function FetchUpdatedCount()
    {
        if (apiURL && apiURL.updatedRecords)
        {
            const api_url = apiURL.updatedRecords;
            const fetchedResult = await FetchData(api_url, true, setErrorMsg);
            setUpdatedRecords(fetchedResult);
        }
    }

    if (!updatedRecords && countsLoaded && apiURL) FetchUpdatedCount();
  }, [apiURL,updatedRecords, currentToken, countsLoaded]);

  
    //Alert Message for loading      
    const AlertMessage = ({ checkValue, checkName }) => {
        const isLoading = (!!checkValue) ? "success" : "info";
        return <Alert severity={isLoading}>{checkName} {(!!checkValue) ? " loaded" : " loading" }</Alert>
    };

    //return allCount && !loading ? (
    return (allCount && countsLoaded && dataLoaded) ? (
    <Box sx={homePageStyle}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <DashboardChartsForm
            allData={allCount}
            riskData={riskCount}
            peopleData={peopleCount}
            locationData={locationCount}
            miscellaneousData={miscellaneousCount}
          />
        </Grid>
        <Grid item>
           <DashboardTablesForm expiredData={expiredRecords} reviewData={reviewRecords} updatedData={updatedRecords} />
        </Grid>
      </Grid>
    </Box>
  ) :
  (
    <>
    <Alert severity="warning">Loading dashboard data...</Alert>
    <AlertMessage checkValue={allCount} checkName={'All Count'} />
                
    <AlertMessage checkValue={riskCount} checkName="Risk Count" />
    <AlertMessage checkValue={peopleCount} checkName="People Count" />
    <AlertMessage checkValue={locationCount} checkName="Location Count" />
    <AlertMessage checkValue={miscellaneousCount} checkName={"Miscellaneous Count"} />

    <AlertMessage checkValue={expiredRecords} checkName={'Expired Records'} />                 
    <AlertMessage checkValue={reviewRecords} checkName={"Review Records"} />
    <AlertMessage checkValue={updatedRecords} checkName={"Updated Records"} />
              
    </>      
  );
}

export default Homepage;
