//#region header */
/**************************************************************************************************
//
//  Description: Edit Loss Confirmation Dialog
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   16.06.21 Sean Flook         WI39345 Changes after UX review.
//    003   17.06.21 Sean Flook         WI39345 Set width of dialog.
//    004   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    005   28.06.21 Sean Flook         WI39345 Set the correct colour for the buttons.
//    006   01.07.21 Sean Flook         WI39345 Increased font size.
//    007   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, { useState, useEffect } from "react";

import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import DiscardIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CloseIcon from "@mui/icons-material/Close";

import { dialogButtonStyle } from "../utils/AAStyles";

/* #endregion imports */

const ConfirmEditLossDialog = ({ isOpen, title, message, handleSaveClick, handleReturnClick, handleDisposeClick }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} aria-labelledby="confirm-edit-loss-dialog" fullWidth maxWidth="xs">
      <DialogTitle id="confirm-edit-loss-dialog">{title ? title : "Unsaved changes"}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveClick}
          autoFocus
          variant="contained"
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button onClick={handleDisposeClick} sx={dialogButtonStyle} startIcon={<DiscardIcon />}>
          Discard
        </Button>
        <Button onClick={handleReturnClick} sx={dialogButtonStyle} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEditLossDialog;
