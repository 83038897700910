/* #region header */
/**************************************************************************************************
//
//  Description: XDM Data form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   07.05.21 Peter Bryden               Updated category data source
//    004   10.05.21 Sean Flook         WI39345 Applied fix caused by above changes.
//    005   10.05.21 Sean Flook         WI39345 Removed DataSource as no longer required.
//    006   10.05.21 Sean Flook         WI39345 Use correct field name for entryDate.
//    007   12.05.21 Sean Flook         WI39345 Changes required to display the icons in the select controls.
//    008   12.05.21 Sean Flook         WI39345 Do not try and convert a date if we do not have a date.
//    009   14.05.21 Sean Flook         WI39345 Ensure the tools are right aligned and display previous and next buttons if required.
//    010   19.05.21 Sean Flook         WI39345 Handle changing records.
//    012   20.05.21 Sean Flook         WI39345 Various design tweaks.
//    013   25.05.21 Sean Flook         WI39345 Added helperText for each of the components.
//    014   27.05.21 Sean Flook         WI39345 Changed height of notes field.
//    015   01.06.21 Sean Flook         WI39345 Changes required for new and changed fields as well as highlighting icons on mouse over.
//    016   08.06.21 Sean Flook         WI39345 Fixed alignment of items in title bar.
//    017   09.06.21 Sean Flook         WI39345 Changes required for new fields.
//    018   16.06.21 Sean Flook         WI39345 Display delete confirmation on this page and changed the save button.
//    019   17.06.21 Sean Flook         WI39345 Changes to get items to align on the toolbar.
//    020   17.06.21 Sean Flook         WI39345 Prevent wrapping of title.
//    021   18.06.21 Sean Flook         WI39345 Pass current data in call to onRecordChange.
//    022   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    023   28.06.21 Sean Flook         WI39345 Correctly set the colour for the save button.
//    024   01.07.21 Sean Flook         WI39345 Correctly handle setting the hover colour on the buttons.
//    025   20.10.21 Sean Flook         WI39823 Change long hyphens to short hyphens.
//    026   11.11.21 Sean Flook         WI39823 If we do not have a category there is no need to change the hyphens.
//    027   19.01.22 Sean Flook         WI39975 Do not set the external id label depending on the template being used.
//    028   22.06.22 Joel Benford       WI39940 Hook up lastUser to CurrentUser context
//    029   02.08.22 Joel Benford       WI40068 Change category lookup useRef -> useState so it renders
//    030   04.08.22 Joel Benford       WI40069 Pick out expected model errors and show with relevant fields
//    031   08.08.22 Joel Benford       WI40072 Render pkId of -1 as empty string
//    032   17.08.22 Joel Benford       WI40257 Ignore control fields on willLoseData
//    033   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    034   12.12.23 Sean Flook                 Fix MUI for React 18
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useState, useEffect, useContext, useCallback } from "react";
import { SymphonyUserContext } from "../context/symphonyUserContext";
import clsx from "clsx";
import AAActionButton from "./../components/AAActionButton";
import AuthorityCodes from "./../data/AuthorityCodes";
import LocationCategoryAmbulance from "./../data/LocationCategoryAmbulance";
import LocationCategoryFire from "./../data/LocationCategoryFire";
import LocationCategoryPolice from "./../data/LocationCategoryPolice";
import MiscellaneousCategoryAmbulance from "./../data/MiscellaneousCategoryAmbulance";
import MiscellaneousCategoryPolice from "./../data/MiscellaneousCategoryPolice";
import PeopleCategoryAmbulance from "./../data/PeopleCategoryAmbulance";
import PeopleCategoryFire from "./../data/PeopleCategoryFire";
import PeopleCategoryPolice from "./../data/PeopleCategoryPolice";
import RiskCategoryAmbulance from "../data/RiskCategoryAmbulance";
import RiskCategoryFire from "../data/RiskCategoryFire";
import RiskCategoryPolice from "../data/RiskCategoryPolice";
import SecurityClassification from "../data/SecurityClassification";
import { Grid, Typography, Tooltip, Box, Button } from "@mui/material";
import AAReadOnlyControl from "../components/AAReadOnlyControl";
import AANumberControl from "../components/AANumberControl";
import AATextControl from "../components/AATextControl";
import AAPhoneControl from "../components/AAPhoneControl";
import AASelectControl from "../components/AASelectControl";
import AADateControl from "../components/AADateControl";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import { FormatDate } from "../components/tables/DashboardFormattingUtils";
import SaveIcon from "@mui/icons-material/Save";
import ObjectComparison from "../utils/ObjectComparison";
import { grey } from "@mui/material/colors";
import { formToolbarTextStyle, xdmDataFormStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/system";

/* #endregion imports */

function XdmRecordForm(props) {
  const theme = useTheme();

  const data = props.data;
  const template = props.template;
  const title = props.title;
  const recordNo = props.recordNo;
  const totalRecords = props.totalRecords;
  const onReturnClick = props.onReturnClick;
  const onSaveClick = props.onSaveClick;
  const onDeleteClick = props.onDeleteClick;
  const onRecordChange = props.onRecordChange;
  const logEdit = props.logEdit;

  const [categoryLookup, setCategoryLookup] = useState([]);
  const [currentEditState, setCurrentEditState] = useState(false);

  const [externalId, setExternalId] = useState(data.externalId);
  const [category, setCategory] = useState(data.category ? data.category.replace("–", "-") : null);
  const [role, setRole] = useState(data.role);
  const [name, setName] = useState(data.name);
  const [notes, setNotes] = useState(data.notes);
  const [originator, setOriginator] = useState(data.originator);
  const [phone, setPhone] = useState(data.phone);
  const [reviewDate, setReviewDate] = useState(data.reviewDate);
  const [expiryDate, setExpiryDate] = useState(data.expiryDate);
  const [security, setSecurity] = useState(data.securityClassification);
  const [peopleName, setPeopleName] = useState(data.peopleName);

  const [dataSourceError, setDataSourceError] = useState(null);
  const [uprnError, setUprnError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [originatorError, setOriginatorError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [roleError, setRoleError] = useState(null);
  const [peopleNameError, setPeopleNameError] = useState(null);
  const [dbError, setDbError] = useState(null);
  const [haveError, setHaveError] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { currentUser } = useContext(SymphonyUserContext);

  const handleExternalIdChangeEvent = (event) => {
    setExternalId(parseInt(event.target.value));
  };
  const handleCategoryChangeEvent = (newValue) => {
    setCategory(newValue.replace("–", "-"));
  };
  const handleRoleChangeEvent = (event) => {
    setRole(event.target.value);
  };
  const handleNameChangeEvent = (event) => {
    setName(event.target.value);
  };
  const handleNotesChangeEvent = (event) => {
    setNotes(event.target.value);
  };
  const handleOriginatorChangeEvent = (event) => {
    setOriginator(event.target.value);
  };
  const handlePhoneChangeEvent = (event) => {
    setPhone(event.target.value);
  };
  const handleReviewDateChangeEvent = (newValue) => {
    setReviewDate(newValue);
  };
  const handleExpiryDateChangeEvent = (newValue) => {
    setExpiryDate(newValue);
  };
  const handleSecurityChangeEvent = (newValue) => {
    setSecurity(newValue);
  };
  const handlePeopleNameChangeEvent = (event) => {
    setPeopleName(event.target.value);
  };

  function GetCurrentData() {
    function ConvertDate(date) {
      // console.log("DEBUG ConvertDate", date);
      if (date.toString().includes("T00:00:00")) return date;

      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();

      // console.log(
      //   "DEBUG ConvertDate",
      //   date,
      //   yyyy + "-" + mm + "-" + dd + "T00:00:00"
      // );
      return yyyy + "-" + mm + "-" + dd + "T00:00:00";
    }

    const now = new Date();

    return {
      pkId: data.pkId,
      template: data.template,
      dataType: data.dataType,
      uprn: data.uprn,
      authorityId: data.authorityId,
      externalId: externalId,
      category: category,
      role: role,
      name: name,
      notes: notes,
      originator: originator,
      phone: phone,
      expiryDate: expiryDate && ConvertDate(expiryDate),
      entryDate: data.entryDate,
      reviewDate: reviewDate && ConvertDate(reviewDate),
      securityClassification: security,
      peopleName: peopleName,
      userName: currentUser.auditName,
      timeStamp: now,
    };
  }

  async function handleSaveClick() {
    //tries to pick out a JSON object in larger string by identifying outer "{" and "}"
    //returns the object, or null on error/failure
    function extractJsonObject(errString) {
      let jsonObject = null;
      let jsonString = "";
      const jsonStart = errString.indexOf("{");
      const jsonEnd = errString.lastIndexOf("}");
      if (jsonStart && jsonEnd) {
        jsonString = errString.substring(jsonStart, jsonEnd + 1);
        try {
          jsonObject = JSON.parse(jsonString);
        } catch {
          //console.log("DEBUG JSON.parse exception in extractJsonObject");
        }
      }
      return jsonObject;
    }

    //finds errors following the pattern used by model validation (NB others may use same pattern)
    function findLikelyModelErrors(saveResultString) {
      let results = [];
      if (
        //does it have the outer fields we expect in a model validation error?
        saveResultString.key &&
        saveResultString.key === "dbError" &&
        saveResultString.error
      ) {
        const payload = extractJsonObject(saveResultString.error);
        if (
          //does it have the inner fields we expect in a model validation error?
          payload &&
          payload.errors &&
          payload.title &&
          payload.title === "One or more validation errors occurred."
        ) {
          //don't know what field names will be (e.g. "Name", "Phone"), so convert to array [of [key, value] arrays]
          for (let field of Object.entries(payload.errors)) {
            results.push({
              fieldName: field[0],
              text: field[1].reduce((a, v) => a + v + " ", "").trim(),
            });
          }
        }
      }
      return results;
    }

    const saveData = GetCurrentData();
    // console.log("DEBUG handleSaveForm", saveData);

    setDataSourceError(null);
    setUprnError(null);
    setCategoryError(null);
    setDescriptionError(null);
    setNotesError(null);
    setOriginatorError(null);
    setPhoneError(null);
    setRoleError(null);
    setPeopleNameError(null);
    setDbError(null);
    setHaveError(false);

    const saveErrors = await onSaveClick(saveData);

    if (saveErrors && saveErrors.length > 0) {
      for (let validationError of saveErrors) {
        let notAnExpectedModelError = false;

        //pick out and handle expected errors from model validation (e.g. data too long)
        const likelyModelErrors = findLikelyModelErrors(validationError);
        //console.log("DEBUG expectedError", likelyModelErrors);
        for (let likelyModelError of likelyModelErrors) {
          switch (likelyModelError.fieldName) {
            case "Category":
              setCategoryError(likelyModelError.text);
              break;
            case "Name": // field with label "Description" in GUI is called "Name" in api model
              setDescriptionError(likelyModelError.text);
              break;
            case "Notes":
              setNotesError(likelyModelError.text);
              break;
            case "Originator":
              setOriginatorError(likelyModelError.text);
              break;
            case "Phone":
              setPhoneError(likelyModelError.text);
              break;
            case "Role":
              setRoleError(likelyModelError.text);
              break;
            case "PeopleName":
              setPeopleNameError(likelyModelError.text);
              break;
            default:
              notAnExpectedModelError = true;
              break;
          }
        }

        //handle other errors, not sure whether API actually raises all of these in the format the code below expects
        switch (validationError.key) {
          case "dataSource":
            setDataSourceError(validationError.error);
            break;

          case "uprn":
            setUprnError(validationError.error);
            break;

          case "category":
            setCategoryError(validationError.error);
            break;

          case "description":
            setDescriptionError(validationError.error);
            break;

          case "dbError":
            if (notAnExpectedModelError || likelyModelErrors.length === 0) setDbError(validationError.error);
            break;

          default:
            console.log("ERROR [handleSaveClick] Unknown key", validationError);
            break;
        }
      }

      // Set this last to ensure the errors are displayed correctly
      setHaveError(true);
    }
  }

  const handlePreviousClick = () => {
    const currentData = GetCurrentData();
    onRecordChange(recordNo - 1, currentData);
  };

  const handleNextClick = () => {
    const currentData = GetCurrentData();
    onRecordChange(recordNo + 1, currentData);
  };

  const handleReturnClick = () => {
    const saveData = GetCurrentData();
    onReturnClick(data, saveData);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = (deleteConfirmed) => {
    setOpenDeleteConfirmation(false);
    const pkId = data && data.pkId ? data.pkId : -1;

    if (deleteConfirmed && pkId && pkId > 0) {
      onDeleteClick(data, pkId);
    }
  };

  const getToolbarButtonStyle = (disabled) => {
    if (disabled)
      return {
        color: "#FFFFFF",
        backgroundColor: theme.palette.text.disabled,
        marginLeft: theme.spacing(1),
      };
    else
      return {
        color: "#FFFFFF",
        backgroundColor: "#2A6EBB",
        "&:hover": {
          backgroundColor: "#00AEEF",
          color: "#FFFFFF",
        },
        marginLeft: theme.spacing(1),
      };
  };

  const setupCategories = useCallback(() => {
    const allCategoryLookups = [
      { id: "PoliceRisk", lookup: RiskCategoryPolice },
      { id: "PolicePeople", lookup: PeopleCategoryPolice },
      { id: "PoliceLocation", lookup: LocationCategoryPolice },
      { id: "PoliceMiscellaneous", lookup: MiscellaneousCategoryPolice },
      { id: "AmbulanceRisk", lookup: RiskCategoryAmbulance },
      { id: "AmbulancePeople", lookup: PeopleCategoryAmbulance },
      { id: "AmbulanceLocation", lookup: LocationCategoryAmbulance },
      { id: "AmbulanceMiscellaneous", lookup: MiscellaneousCategoryAmbulance },
      { id: "FireRisk", lookup: RiskCategoryFire },
      { id: "FirePeople", lookup: PeopleCategoryFire },
      { id: "FireLocation", lookup: LocationCategoryFire },
    ];
    const lookupCategory = allCategoryLookups.find((c) => c.id === `${data.template}${data.dataType}`);
    if (lookupCategory) setCategoryLookup(lookupCategory.lookup);
  }, [data.template, data.dataType]);

  useEffect(() => {
    //console.log("DEBUG this should run once on opening XdmRecordForm");
    setupCategories();
  }, [setupCategories]);

  useEffect(() => {
    logEdit(data, {
      pkId: data.pkId,
      dataType: data.dataType,
      template: data.template,
      uprn: data.uprn,
      authorityId: data.authorityId,
      externalId: externalId,
      category: category,
      role: role,
      name: name,
      notes: notes,
      originator: originator,
      phone: phone,
      expiryDate: expiryDate,
      entryDate: data.entryDate,
      reviewDate: reviewDate,
      securityClassification: security,
      peopleName: peopleName,
      timeStamp: data.timeStamp,
      userName: data.userName,
    });

    const willLoseData =
      data.pkId < 0 ||
      !ObjectComparison(
        data,
        {
          pkId: data.pkId,
          dataType: data.dataType,
          template: data.template,
          uprn: data.uprn,
          authorityId: data.authorityId,
          externalId: externalId,
          category: category,
          role: role,
          name: name,
          notes: notes,
          originator: originator,
          phone: phone,
          expiryDate: expiryDate,
          entryDate: data.entryDate,
          reviewDate: reviewDate,
          securityClassification: security,
          peopleName: peopleName,
          timeStamp: data.timeStamp,
          userName: data.userName,
        },
        [
          "timeStamp",
          "userName",
          "xrowid",
          "xdatasourceid",
          "xdatasourcekey",
          "searchedfortext",
          "score",
          "crud",
          "deleteId",
          "errorMessage",
          "importFailedRecordStatus",
          "updateId",
          "windowsUserName",
          "xdmQueuedRecord",
        ]
      );
    //console.log("DEBUG XdmRecordForm.willLoseData", willLoseData);
    setCurrentEditState(willLoseData);
  }, [
    template,
    data,
    logEdit,
    externalId,
    category,
    role,
    name,
    notes,
    originator,
    phone,
    expiryDate,
    reviewDate,
    security,
    peopleName,
  ]);

  useEffect(() => {
    // console.log("[SF] useEffect XdmRecordForm data", data);
    setExternalId(data.externalId);
    setCategory(data.category ? data.category.replace("–", "-") : null);
    setRole(data.role);
    setName(data.name);
    setNotes(data.notes);
    setOriginator(data.originator);
    setPhone(data.phone);
    setReviewDate(data.reviewDate);
    setExpiryDate(data.expiryDate);
    setSecurity(data.securityClassification);
    setPeopleName(data.peopleName);
  }, [data]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="baseline"
      sx={{
        width: "100%",
        backgroundColor: grey[100],
      }}
    >
      <Grid
        container
        sx={{
          paddingLeft: theme.spacing(0),
          paddingRight: theme.spacing(1),
          width: "100%",
          backgroundColor: "#ffffff",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "#d7d7d7",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <AAActionButton
                variant="return"
                tooltipTitle="Return"
                tooltipPlacement="top"
                onClick={handleReturnClick}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" display="inline-flex" sx={formToolbarTextStyle}>
                | {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {totalRecords > 1 && (
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {recordNo !== 1 && (
                <Grid item>
                  <AAActionButton
                    variant="previous"
                    tooltipTitle="Previous record"
                    tooltipPlacement="top"
                    onClick={handlePreviousClick}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography variant="subtitle2" display="inline-flex" sx={formToolbarTextStyle}>
                  {` ${recordNo} of ${totalRecords} `}
                </Typography>
              </Grid>
              {recordNo !== totalRecords && (
                <Grid item>
                  <AAActionButton
                    variant="next"
                    tooltipTitle="Next record"
                    tooltipPlacement="top"
                    onClick={handleNextClick}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <AAActionButton
            variant="delete"
            buttonLabel="Delete"
            disabled={data.pkId < 0}
            tooltipTitle="Delete"
            tooltipPlacement="top"
            onClick={handleDeleteClick}
          />
          <Tooltip title="Save" arrow placement="top">
            <Button
              variant="contained"
              disableElevation
              disabled={!currentEditState}
              size="small"
              sx={getToolbarButtonStyle(!currentEditState)}
              startIcon={<SaveIcon />}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Tooltip>
        </Grid>

        {haveError ? (
          <Typography variant="subtitle2" color="error">
            Please correct the validation errors listed below before trying to save again.
          </Typography>
        ) : (
          ""
        )}
      </Grid>

      <Box sx={xdmDataFormStyle}>
        <AAReadOnlyControl label="ID" value={data.pkId && data.pkId >= 0 ? data.pkId.toString() : ""} />
        <AANumberControl
          label="External id"
          isEditable
          value={externalId}
          helperText="This is the reference number to this record by the originating system for this data."
          onChange={handleExternalIdChangeEvent}
        />
        <AASelectControl
          label="Category"
          isEditable
          isRequired
          lookupData={categoryLookup}
          lookupId="category"
          lookupLabel="category"
          lookupIcon="avatar_icon"
          value={category}
          errorText={categoryError}
          helperText="This is the high level category for the record."
          onChange={handleCategoryChangeEvent}
        />
        <AATextControl
          label="Description"
          isEditable
          isRequired
          maxLength={300}
          minLines={3}
          maxLines={5}
          value={name}
          errorText={descriptionError}
          helperText="This is a short description for the record – e.g. “Dangerous dog in residence”."
          id={data.pkId ? data.pkId : -1}
          onChange={handleNameChangeEvent}
        />
        {template === "Ambulance" && (
          <AATextControl
            label="People name"
            isEditable
            maxLength={100}
            value={peopleName}
            errorText={peopleNameError}
            helperText="This is to record the name of the person(s) the record refers to. This must only be used in accordance with the organisation’s policy on privacy."
            id={data.pkId ? data.pkId : -1}
            onChange={handlePeopleNameChangeEvent}
          />
        )}
        {title.startsWith("People record") && (
          <AATextControl
            label="Role"
            isEditable
            maxLength={255}
            value={role}
            errorText={roleError}
            helperText="This is a description of the role of the person(s) in relation to the record. For example the category could be “Person at Increased Risk”. The role then might be “Child” or “Mother”."
            id={data.pkId ? data.pkId : -1}
            onChange={handleRoleChangeEvent}
          />
        )}
        <AATextControl
          label="Notes"
          isEditable
          maxLength={1024}
          minLines={3}
          maxLines={17}
          value={notes}
          errorText={notesError}
          helperText="Used for recording the in-depth detail for the record."
          id={data.pkId ? data.pkId : -1}
          onChange={handleNotesChangeEvent}
        />
        <AATextControl
          label="Originator"
          isEditable
          maxLength={100}
          value={originator}
          errorText={originatorError}
          helperText="The person or a reference for the system."
          id={data.pkId ? data.pkId : -1}
          onChange={handleOriginatorChangeEvent}
        />
        <AAPhoneControl
          label="Phone number"
          isEditable
          value={phone}
          errorText={phoneError}
          helperText="The contact number if more details are required for this record."
          onChange={handlePhoneChangeEvent}
        />
        <AADateControl
          label="Entry date"
          value={data.entryDate}
          helperText="The date on which the record was created."
        />
        <AAReadOnlyControl label="Last updated" value={FormatDate(data.timeStamp)} />
        <AADateControl
          label="Expiry date"
          isEditable
          value={expiryDate}
          helperText="The date at which the record is no longer valid."
          onChange={handleExpiryDateChangeEvent}
        />
        <AADateControl
          label="Review date"
          isEditable
          value={reviewDate}
          helperText="The date for which the status of the record should be reviewed for continuing validity."
          onChange={handleReviewDateChangeEvent}
        />
        <AASelectControl
          label="Security classification"
          isEditable
          lookupData={SecurityClassification}
          lookupId="classification"
          lookupLabel="description"
          lookupIcon="avatar_icon"
          value={security}
          helperText="The security classification of the record."
          onChange={handleSecurityChangeEvent}
        />
        <AASelectControl
          label="Authority"
          lookupData={AuthorityCodes}
          lookupId="id"
          lookupLabel="authority"
          value={data.authorityId}
        />
        <AAReadOnlyControl label="Updated by" value={data.userName} />
        {dataSourceError !== null ? (
          <Typography variant="subtitle2" color="error">
            {dataSourceError}
          </Typography>
        ) : (
          ""
        )}
        {uprnError !== null ? (
          <Typography variant="subtitle2" color="error">
            {uprnError}
          </Typography>
        ) : (
          ""
        )}
        {dbError !== null ? (
          <Typography variant="subtitle2" color="error">
            {dbError}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      <div>
        <ConfirmDeleteDialog
          title="Delete Xtended data record"
          subtitle={`${category}`}
          content="On deletion this record will be permanently removed and cannot be retrieved."
          open={openDeleteConfirmation}
          onClose={handleCloseDeleteConfirmation}
        />
      </div>
    </Grid>
  );
}

export default XdmRecordForm;
