//--------------------------------------------------------------------------------------------------
//
//  Description: Password Helper utilities
//
//  Copyright:    © 2024 Idox Group Limited
//
//--------------------------------------------------------------------------------------------------

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        document.execCommand('copy', true, text);
    }
}

export const getRndNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const RndPasswordGenerator = (minLength) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()<>,.?/[]{}-=_+|/0123456789';

    let password = '';
    for (let i = 0; i < minLength; i++)
        password += characters[getRndNumber(0, characters.length - 1)];

    return password;
}
