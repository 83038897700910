/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Search component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   12.05.21 Sean Flook         WI39345 Changed to allow the dropdown arrow to be displayed.
//    004   13.05.21 Sean Flook         WI39345 Do not hide the clear button.
//    005   13.05.21 Sean Flook         WI39345 Set the no options text to be more appropriate.
//    006   01.06.21 Sean Flook         WI39345 Added postcode to the property context.
//    007   17.06.21 Sean Flook         WI39345 Format the address and added a rounded border.
//    008   18.06.21 Sean Flook         WI39345 corrected field name.
//    009   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    010   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    011   15.10.21 Peter Bryden               Updated error in address display after selection.
//    012   09.03.22 Peter Bryden       WI40103	Added in Symphony API Security Authentication
//    013   25.08.22 Joel Benford       WI40269 Tidy Console.logs
//    014   05.09.22 Joel Benford       WI40275 Safe synchronous call to async fn returning data
//    015   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    016   12.12.23 Sean Flook                 Fix MUI for React 18
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext, useState, useEffect } from "react";

import PropertyContext from "../context/propertyContext";
import { SymphonyUserContext } from "../context/symphonyUserContext";

import { Autocomplete, TextField, Box, InputAdornment, Popper, IconButton, Grid, Typography } from "@mui/material";

import { autocompleteClasses } from "@mui/material/Autocomplete";
import { GetSearchURL, authBearerHeader } from "../configuration/AAConfig";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { ClearSearchIconStyle } from "../utils/AAStyles";
import { useTheme, styled } from "@mui/system";

/* #endregion imports */

const apiFetch = async (url, headers, dataIfAborted, signal) => {
  try {
    const response = await fetch(url, {
      headers: headers,
      crossDomain: true,
      method: "GET",
      signal,
    });

    const data = await response.json();
    return data;
  } catch (err) {
    if (err.name === "AbortError") {
      //console.log("DEBUG Request Aborted ");
      return dataIfAborted;
    }
    return err;
  }
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.paper}`]: {
    marginTop: "20px",
    border: "1px",
    borderColor: "#D7D7D7",
    boxShadow: `4px 4px 7px ${"#E1E1E180"}`,
    borderRadius: "6px",
  },
});

function AASearch({ placeholder }) {
  const theme = useTheme();

  const propertyContext = useContext(PropertyContext);
  const [data, setData] = useState([]);
  const [urlDetails, setUrlDetails] = useState(null);
  const [search, setSearch] = useState();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { currentToken } = useContext(SymphonyUserContext);

  const GetApiSite = async () => {
    if (!urlDetails) {
      const url = await GetSearchURL();
      setUrlDetails(url);
    }
  };

  function addressToTitleCase(address, postcode) {
    const retAddress =
      !address || address.length === 0
        ? address
        : address
            .replace(postcode, "")
            .replace(/[\r\n]/gm, ", ")
            .replaceAll(", , ", ", ")
            .replaceAll(", , ", ", ")
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }) + postcode;

    //console.log("DEBUG addressToTitleCase ", address, retAddress);

    return retAddress;
  }

  useEffect(() => {
    //no-result wrapper can safely be called synchronously to run async function returning a value
    async function fetchAsyncAndSetData(url, whileWaiting, signal) {
      try {
        const result = await apiFetch(
          url,
          authBearerHeader(currentToken),
          whileWaiting,
          signal
        );
        setData(result);
      } catch (err) {
        console.log("Error fetching search result from API", err);
      }
    }

    const controller = new AbortController();

    if (urlDetails && search && search.length > 0) {
      const whileWaiting = [{ uprn: 0, address: "Loading Data...", postcode: "" }];

      setData(whileWaiting);

      const signal = controller.signal;

      const url = `${urlDetails.url}/${search}?nMaxRecords=${urlDetails.info.maxRecords}`;
      fetchAsyncAndSetData(url, whileWaiting, signal);
    } else {
      //console.log("DEBUG no filter data");
      setData([]);
    }

    return () => {
      setData([{ uprn: 0, address: "Loading Data...", postcode: "" }]);
      controller.abort();
    };
  }, [search, urlDetails, currentToken]);

  const onSearchChange = (event, newInputValue) => {
    if (!urlDetails) GetApiSite();

    setSearch(newInputValue);
    setInputValue(newInputValue);
  };

  /**
   * Event to handle when the clear search button is clicked.
   */
  const handleClearSearch = () => {
    setValue(null);
    setInputValue("");
    setSearch(null);
  };

  const onSelectProperty = (event, newValue) => {
    // console.log("DEBUG onSelectProperty", newValue);
    if (newValue)
      propertyContext.onPropertyChange(
        newValue.uprn,
        newValue.formattedaddress,
        newValue.postcode,
        newValue.longitude,
        newValue.latitude
      );

    setValue(newValue);
  };

  return currentToken ? (
    <Autocomplete
      id="ads-search"
      open={propertyContext.searchPopupOpen}
      sx={{ color: "inherit" }}
      getOptionLabel={(option) => addressToTitleCase(option.address, option.postcode)}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      filterOptions={(x) => x}
      noOptionsText="No search results"
      options={data}
      onOpen={() => {
        if (search) {
          propertyContext.onSearchOpen(true);
        }
      }}
      onClose={() => {
        propertyContext.onSearchOpen(false);
      }}
      value={value}
      onChange={onSelectProperty}
      inputValue={inputValue}
      onInputChange={onSearchChange}
      PopperComponent={StyledPopper}
      renderOption={(props, option) => {
        return (
          <Grid
            {...props}
            container
            alignItems="center"
            spacing={1}
            sx={{
              pt: theme.spacing(0.5),
              pb: theme.spacing(1),
              color: "#535353",
              "&:hover": {
                cursor: "pointer",
                color: "#2A6EBB",
                backgroundColor: "#E6F3F9",
              },
            }}
          >
            <Grid item xs>
              <Typography sx={{ fontSize: "15px" }}>{addressToTitleCase(option.address, option.postcode)}</Typography>
            </Grid>
          </Grid>
        );
      }}
      renderInput={(params) => (
        <Box
          ref={params.InputProps.ref}
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "18px",
            marginBottom: theme.spacing(1.5),
            display: "inline-flex",
            height: "36px",
            transition: theme.transitions.create("width"),
            [theme.breakpoints.up("sm")]: {
              width: "176px",
              borderColor: "#D7D7D7",
              "&:focus-within": {
                width: "640px",
                borderColor: "#797979",
              },
            },
          }}
        >
          <TextField
            {...params}
            variant="standard"
            placeholder={placeholder}
            sx={{
              color: "#535353",
              fontFamily: "Nunito Sans",
              fontSize: "15px",
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
              width: "100%",
            }}
            InputProps={{
              ...params.inputProps,
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton id="btnClear" onClick={handleClearSearch} aria-label="clear button" size="small">
                    <ClearIcon sx={ClearSearchIconStyle(search)} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  ) : (
    <div hidden />
  );
}

export default AASearch;
