//#region header */
/**************************************************************************************************
//
//  Description: Login form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   24.06.21 Joel Benford        WI39518 Initial Revision.
//    002   10.08.21 Joel Benford        WI39518 Interim check-in for new SymphonySecurity API
//    003   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import { Box, FormGroup, FormControl, FormLabel, Button, InputLabel, Input, Typography } from "@mui/material";
import { loginStyle, dialogButtonStyle } from "../utils/AAStyles";
/* #endregion imports */

const LoginForm = ({submitHandler, message }) => {
  const [state, setState] = useState({ auditname: "", password: "" });

  const handleChange = (e) => setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitHandler(state.auditname, state.password);
  };

  return (
    <Box sx={loginStyle}>
      <form onSubmit={handleSubmit}>
        <FormLabel sx={{ fontWeight: 700 }}>Please login:</FormLabel>
        <FormGroup>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="auditname">Name</InputLabel>
            <Input id="auditname" value={state.auditname} onChange={handleChange} />
          </FormControl>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input type="password" id="password" value={state.password} onChange={handleChange} />
          </FormControl>
          <Button type="submit" variant="outlined" color="primary" sx={dialogButtonStyle}>
            Login
          </Button>
        </FormGroup>
      </form>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default LoginForm;
