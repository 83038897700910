/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Application Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Display the address and UPRN of current property.
//    004   25.05.21 Sean Flook         WI39345 removed bottom shadow.
//    005   01.06.21 Sean Flook         WI39345 Updated display of title and added highlighting of action icons.
//    006   02.06.21 Sean Flook         WI39345 Replaced the home and close buttons with action buttons.
//    007   08.06.21 Sean Flook         WI39345 Added a divider and changed position of tooltip.
//    008   16.06.21 Sean Flook         WI39345 Replaced divider with pipe character and moved toolbar to the left.
//    009   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//    010   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    011   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    012   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    013   12.12.23 Sean Flook                 Fix MUI for React 18
//    014   12.12.23 Sean Flook                 Changes required for upgrade of react-router-dom to v6.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Tooltip, Box, alpha } from "@mui/material";
import AAActionButton from "./AAActionButton";
import HelpIcon from "@mui/icons-material/HelpOutline";
import PropertyContext from "../context/propertyContext";
import AASearch from "./AASearch";
import AAHelpDrawer from "./AAHelpDrawer";
import { HomeRoute } from "../PageRouting";
import { navBarWidth, helpBarWidth, appBarTitleStyle } from "../utils/AAStyles";
import { useTheme } from "@mui/system";

/* #endregion imports */

function AAAppBar() {
  const navigate = useNavigate();

  const theme = useTheme();

  const propertyContext = useContext(PropertyContext);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleHomeClick = () => {
    propertyContext.onPropertyChange(0, "", "", 0, 0);
    navigate(HomeRoute);
  };

  function addressToTitleCase(str) {
    return (
      str
        .replace(propertyContext.currentProperty.postcode, "")
        .replace(/[\r\n]/gm, ", ")
        .replaceAll(", , ", ", ")
        .replaceAll(", , ", ", ")
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }) + propertyContext.currentProperty.postcode
    );
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "#FFFFFF",
        width: `calc(100% - ${open ? navBarWidth + helpBarWidth : navBarWidth}px)`,
        height: "56px",
        marginLeft: navBarWidth,
        marginRight: `${open ? helpBarWidth : 0}px`,
        transition: `theme.transitions.create(["margin", "width"], {
        easing: ${open ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp},
        duration: ${open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen},
      })`,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#2A6EBB",
      }}
    >
      <Toolbar sx={{ pl: theme.spacing(0.85) }}>
        {propertyContext.currentProperty && propertyContext.currentProperty.uprn > 0 ? (
          <Fragment>
            <AAActionButton variant="home" tooltipTitle="Home" tooltipPlacement="bottom" onClick={handleHomeClick} />
            <Tooltip title="UPRN - Address" arrow placement="bottom-start">
              <Typography sx={appBarTitleStyle} variant="subtitle1" noWrap align="left">
                | <strong>{propertyContext.currentProperty.uprn}</strong>
                {addressToTitleCase(` - ${propertyContext.currentProperty.formattedAddress}`)}
              </Typography>
            </Tooltip>
          </Fragment>
        ) : (
          <Typography sx={appBarTitleStyle} variant="subtitle1" noWrap align="left">
            XDM Manager
          </Typography>
        )}
        <Box
          sx={{
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            "&:hover": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
              marginLeft: theme.spacing(1),
              width: "auto",
            },
          }}
        >
          <AASearch placeholder="Search…" />
        </Box>
        <IconButton
          aria-label="open help drawer"
          onClick={handleDrawerOpen}
          size="large"
          sx={
            open
              ? {
                  display: "none",
                }
              : {
                  color: "#535353",
                  "&:hover": {
                    color: "#2A6EBB",
                  },
                }
          }
        >
          <HelpIcon />
        </IconButton>
      </Toolbar>
      <AAHelpDrawer open={open} handleDrawerClose={handleDrawerClose} />
    </AppBar>
  );
}

export default AAAppBar;
