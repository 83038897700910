/* #region header */
/**************************************************************************************************
//
//  Description: Expired dashboard table
//
//  Copyright:    © 2021 - 2023 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   07.05.21 Peter Bryden               Updated field names and refactored formatting to dashboardformattingutils
//    003   10.05.21 Sean Flook         WI39345 Format the Expiry date
//    004   12.05.21 Sean Flook         WI39345 Renamed functions and added default sorting.
//    005   12.05.21 Sean Flook         WI39345 Go to the property when double clicking on a row.
//    006   25.05.21 Sean Flook         WI39345 Increased width of type column.
//    007   01.06.21 Sean Flook         WI39345 Added postcode to the property context.
//    008   10.06.21 Sean Flook         WI39345 Format the address and right align the dates.
//    009   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//    010   01.07.21 Sean Flook         WI39345 Changes for highlighting the row.
//    011   13.10.21 Sean Flook         WI39823 Changed to use formattedAddress.
//    012   20.10.21 Sean Flook         WI39823 Changed field name.
//    013   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
import PropertyContext from "../../context/propertyContext";
import { DataGrid } from "@mui/x-data-grid";
import {
  GetTypeIcon,
  FormatExpiryReviewDate,
  FormatDate,
  FormatAddressToTitleCase,
} from "../tables/DashboardFormattingUtils";

/* #endregion imports */

const columns = [
  { field: "id" },
  { field: "uprn" },
  {
    field: "formattedAddress",
    headerName: "Property",
    flex: 15,
    type: "string",
    renderCell: FormatAddressToTitleCase,
  },
  { field: "xdmType", headerName: "Type", flex: 4, renderCell: GetTypeIcon },
  {
    field: "category",
    headerName: "Category",
    flex: 10,
    type: "string",
    cellClassName: "table-category",
  },
  {
    field: "expiryDate",
    headerName: "Expiry date",
    flex: 5,
    type: "date",
    valueGetter: (params) => new Date(params.value),
    cellClassName: "table-date-overdue",
    renderCell: FormatExpiryReviewDate,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "lastUpdated",
    headerName: "Last updated",
    flex: 6,
    type: "dateTime",
    valueGetter: (params) => new Date(params.value),
    valueFormatter: FormatDate,
    align: "right",
    headerAlign: "right",
  },
  { field: "latitude" },
  { field: "longitude" },
  { field: "postcode" },
];

function DashboardExpired({ tableData }) {
  const propertyContext = useContext(PropertyContext);

  const handleRowClick = (param, event) => {
    // console.log("DEBUG handleRowClick", param);

    if (param) {
      propertyContext.onPropertyChange(
        param.row.uprn,
        param.row.formattedAddress,
        param.row.postcode,
        param.row.longitude,
        param.row.latitude
      );
    }
  };

  return (
    <div
      style={{
        width: "89vw",
        height: "37vh",
        overflowY: "auto",
        padding: 0,
        "& .table-date-overdue": {
          color: "red",
        },
        "& .table-category": {
          paddingLeft: "8px",
        },
      }}
    >
      <DataGrid
        rows={tableData}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              uprn: false,
              latitude: false,
              longitude: false,
              postcode: false,
            },
          },
        }}
        sx={{
          "& .dashboardRow": {
            "&:hover": {
              backgroundColor: "#DFF1F9",
              color: "#2A6EBB",
              cursor: "pointer",
            },
          },
        }}
        autoHeight
        pageSize={25}
        sortModel={[{ field: "expiryDate", sort: "asc" }]}
        getRowClassName={(params) => "dashboardRow"}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default DashboardExpired;
