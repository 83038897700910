export function GetCurrentUserFirstName(currentUser) {
    return currentUser
        ? `${currentUser.firstName ? currentUser.firstName : ""}`
        : "";
}

export function GetCurrentUserName(currentUser) {
    return currentUser
        ? `${currentUser.firstName ? currentUser.firstName : ""} ${currentUser.lastName ? currentUser.lastName : ""}`
        : "";
}