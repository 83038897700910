//#region header */
/**************************************************************************************************
//
//  Description: XDM Data List Form
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   12.05.21 Sean Flook         WI39345 Changes required to display the icons.
//    004   14.05.21 Sean Flook         WI39345 Removed subheader as no longer required.
//    005   19.05.21 Sean Flook         WI39345 Added general add button.
//    006   19.05.21 Sean Flook         WI39345 Added a tooltip to the add button.
//    007   24.05.21 Sean Flook         WI39345 Added icons to the menu items.
//    008   01.06.21 Sean Flook         WI39345 Highlight icons when cursor is hovering over them.
//    009   08.06.21 Sean Flook         WI39345 Changed colour of Add label.
//    010   11.06.21 Sean Flook         WI39345 Added expand and collapse all.
//    011   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    012   28.06.21 Sean Flook         WI39345 Pass through properties used for handling displaying the correct item in the list.
//    013   02.07.21 Sean Flook         WI39345 Only expand the single type when creating a record.
//    014   18.10.21 Sean Flook         WI39823 Handle when we do not return a record when getting the avatar icon.
//    015   19.10.21 Sean Flook         WI39823 riskRec is not an array so access data directly.
//    016   20.10.21 Sean Flook         WI39823 Change long hyphens to short hyphens when getting the avatars.
//    017   10.08.22 Joel Benford       WI39932 Add a hint to records for easier identification
//    018   10.08.22 Joel Benford       WI40085 Cosmetics on "Add Sometype record" menu
//    019   28.09.22 Joel Benford       WI39932 Base hint on description instead of notes
//    020   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, { useState, Fragment, useEffect } from "react";

import { List, Grid, Menu, MenuItem, IconButton, Tooltip, Typography, Box } from "@mui/material";
import AAXDMDataListItem from "../components/AAXDMDataListItem";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { RiskIcon, PeopleIcon, LocationIcon, MiscellaneousIcon } from "../components/AADataTypeIcons";

import LocationCategoryAmbulance from "./../data/LocationCategoryAmbulance";
import LocationCategoryFire from "./../data/LocationCategoryFire";
import LocationCategoryPolice from "./../data/LocationCategoryPolice";
import MiscellaneousCategoryAmbulance from "./../data/MiscellaneousCategoryAmbulance";
import MiscellaneousCategoryPolice from "./../data/MiscellaneousCategoryPolice";
import PeopleCategoryAmbulance from "./../data/PeopleCategoryAmbulance";
import PeopleCategoryFire from "./../data/PeopleCategoryFire";
import PeopleCategoryPolice from "./../data/PeopleCategoryPolice";
import RiskCategoryAmbulance from "../data/RiskCategoryAmbulance";
import RiskCategoryFire from "../data/RiskCategoryFire";
import RiskCategoryPolice from "../data/RiskCategoryPolice";

import { actionIconStyle, actionLabelStyle, menuTextStyle, xdmDataFormStyle } from "../utils/AAStyles";

/* #endregion imports */

const XDMDataListForm = ({ xdmData, template, expandedItem, selectedItem, onItemSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandCollapseLabel, setExpandCollapseLabel] = useState("Expand all");
  const [listState, setListState] = useState("stored");

  const [risks, setRisks] = useState(null);
  const [people, setPeople] = useState(null);
  const [locations, setLocations] = useState(null);
  const [miscellaneous, setMiscellaneous] = useState(null);

  const handleRiskSelection = (pkId, dataIdx, dataLength) => {
    onItemSelect("Risk", pkId, dataIdx, dataLength);
  };

  const handlePeopleSelection = (pkId, dataIdx, dataLength) => {
    onItemSelect("People", pkId, dataIdx, dataLength);
  };

  const handleLocationSelection = (pkId, dataIdx, dataLength) => {
    onItemSelect("Location", pkId, dataIdx, dataLength);
  };

  const handleMiscellaneousSelection = (pkId, dataIdx, dataLength) => {
    onItemSelect("Miscellaneous", pkId, dataIdx, dataLength);
  };

  const handleExpandCollapse = () => {
    if (expandCollapseLabel === "Expand all") {
      setListState("expanded");
      setExpandCollapseLabel("Collapse all");
    } else {
      setListState("collapsed");
      setExpandCollapseLabel("Expand all");
    }
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.nativeEvent.target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRiskAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("Risk", -1, dataLength, newDataLength);
  };
  const handlePeopleAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("People", -1, dataLength, newDataLength);
  };
  const handleLocationAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("Location", -1, dataLength, newDataLength);
  };
  const handleMiscellaneousAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("Miscellaneous", -1, dataLength, newDataLength);
  };

  function GetRiskAvatar(category, returnIcon) {
    let riskLookup = null;
    switch (template) {
      case "Ambulance":
        riskLookup = RiskCategoryAmbulance;
        break;
      case "Fire":
        riskLookup = RiskCategoryFire;
        break;
      case "Police":
        riskLookup = RiskCategoryPolice;
        break;
      default:
        riskLookup = null;
        break;
    }

    if (riskLookup !== null) {
      const riskRec = riskLookup.find((x) => x.category === category.replace("–", "-"));

      if (riskRec) {
        if (returnIcon) return riskRec.avatar_icon;
        else return riskRec.avatar_text;
      } else return undefined;
    }
    return undefined;
  }

  function GetPeopleAvatar(category, returnIcon) {
    let riskLookup = null;
    switch (template) {
      case "Ambulance":
        riskLookup = PeopleCategoryAmbulance;
        break;
      case "Fire":
        riskLookup = PeopleCategoryFire;
        break;
      case "Police":
        riskLookup = PeopleCategoryPolice;
        break;
      default:
        riskLookup = null;
        break;
    }

    if (riskLookup !== null) {
      const riskRec = riskLookup.find((x) => x.category === category.replace("–", "-"));

      if (riskRec) {
        if (returnIcon) return riskRec.avatar_icon;
        else return riskRec.avatar_text;
      } else return undefined;
    }
    return undefined;
  }

  function GetLocationAvatar(category, returnIcon) {
    let riskLookup = null;
    switch (template) {
      case "Ambulance":
        riskLookup = LocationCategoryAmbulance;
        break;
      case "Fire":
        riskLookup = LocationCategoryFire;
        break;
      case "Police":
        riskLookup = LocationCategoryPolice;
        break;
      default:
        riskLookup = null;
        break;
    }

    if (riskLookup !== null) {
      const riskRec = riskLookup.find((x) => x.category === category.replace("–", "-"));

      if (riskRec) {
        if (returnIcon) return riskRec.avatar_icon;
        else return riskRec.avatar_text;
      } else return undefined;
    }
    return undefined;
  }

  function GetMiscellaneousAvatar(category, returnIcon) {
    let riskLookup = null;
    switch (template) {
      case "Ambulance":
        riskLookup = MiscellaneousCategoryAmbulance;
        break;
      case "Police":
        riskLookup = MiscellaneousCategoryPolice;
        break;
      default:
        riskLookup = null;
        break;
    }

    if (riskLookup !== null) {
      const riskRec = riskLookup.find((x) => x.category === category.replace("–", "-"));

      if (riskRec) {
        if (returnIcon) return riskRec.avatar_icon;
        else return riskRec.avatar_text;
      } else return undefined;
    }
    return undefined;
  }

  function GetItemState(reviewDate, expiryDate) {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd + "T00:00:00";

    let itemState;

    if (expiryDate && today >= expiryDate) itemState = "expired";
    else if (reviewDate && today >= reviewDate) itemState = "reviewRequired";
    else itemState = "fine";

    // console.log("DEBUG GetItemState", reviewDate, expiryDate, today, itemState);

    return itemState;
  }

  function generateHint(description, notes) {
    const trimTo = 100;
    const descriptionOrDefault = description || "No description on the record";
    if (descriptionOrDefault.length > trimTo - 4) return descriptionOrDefault.substr(0, trimTo - 5) + " ...";
    else return descriptionOrDefault.substr(0, trimTo - 1);
  }

  useEffect(() => {
    setRisks(xdmData ? xdmData.risk : null);
    setPeople(xdmData ? xdmData.people : null);
    setLocations(xdmData ? xdmData.location : null);
    setMiscellaneous(xdmData ? xdmData.miscellaneous : null);
  }, [xdmData]);

  // console.log("DEBUG XDMDataListForm - Risk", risks);
  // console.log("DEBUG XDMDataListForm - People", people);
  // console.log("DEBUG XDMDataListForm - Locations", locations);
  // console.log("DEBUG XDMDataListForm - Miscellaneous", miscellaneous);
  // console.log("DEBUG XDMDataListForm - propertyContext", propertyContext);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "#d7d7d7",
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Tooltip title={`${expandCollapseLabel} items in list`} arrow placement="right">
              <IconButton onClick={handleExpandCollapse} aria-controls="expand-collapse" size="small">
                {expandCollapseLabel === "Expand all" ? (
                  <KeyboardArrowDownIcon sx={actionIconStyle} />
                ) : (
                  <KeyboardArrowUpIcon sx={actionIconStyle} />
                )}
                <Typography variant="body2" sx={actionLabelStyle}>
                  {expandCollapseLabel}
                </Typography>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Add new record" arrow placement="right">
              <IconButton onClick={handleAddClick} aria-controls="add-menu" aria-haspopup="true" size="small">
                <AddCircleOutlineIcon sx={actionIconStyle} />
                <Typography variant="body2" sx={actionLabelStyle}>
                  Add
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              id="add-menu"
              elevation={2}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem dense onClick={(dataLength) => handleRiskAdd(xdmData.risk.length)}>
                <RiskIcon menu />
                <Typography variant="inherit" sx={menuTextStyle}>
                  Add Risk record
                </Typography>
              </MenuItem>
              <MenuItem dense onClick={(dataLength) => handlePeopleAdd(xdmData.people.length)}>
                <PeopleIcon menu />
                <Typography variant="inherit" sx={menuTextStyle}>
                  Add People record
                </Typography>
              </MenuItem>
              <MenuItem dense onClick={(dataLength) => handleLocationAdd(xdmData.location.length)}>
                <LocationIcon menu />
                <Typography variant="inherit" sx={menuTextStyle}>
                  Add Location record
                </Typography>
              </MenuItem>
              {template && template === "Ambulance" && (
                <MenuItem dense onClick={(dataLength) => handleMiscellaneousAdd(xdmData.miscellaneous.length)}>
                  <MiscellaneousIcon menu />
                  <Typography variant="inherit" sx={menuTextStyle}>
                    Add Miscellaneous record
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Grid>
        </Grid>
      </Box>
      <List
        sx={{
          width: "100%",
          backgroundColor: "#F8F9FA",
          pt: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Box sx={xdmDataFormStyle}>
          <AAXDMDataListItem
            title="Risk"
            itemIcon="Risk"
            itemState={
              expandedItem && expandedItem.length > 0 ? (expandedItem === "Risk" ? "expanded" : "collapsed") : listState
            }
            itemSelect={(pkId, dataIdx, dataLength) => handleRiskSelection(pkId, dataIdx, dataLength)}
            itemAdd={(dataLength) => handleRiskAdd(dataLength)}
            data={
              risks &&
              risks.map((r) => ({
                id: r.dataSource,
                displayText: `${r.category}`,
                avatarIcon: GetRiskAvatar(r.category, true),
                avatarText: GetRiskAvatar(r.category, false),
                state: GetItemState(r.reviewDate, r.expiryDate),
                selectedItem: selectedItem,
                reviewDate: r.reviewDate,
                expiryDate: r.expiryDate,
                lastUpdated: r.timeStamp,
                pkId: r.pkId,
                hint: generateHint(r.name, r.notes),
              }))
            }
          />
          <AAXDMDataListItem
            title="People"
            itemIcon="People"
            itemState={
              expandedItem && expandedItem.length > 0
                ? expandedItem === "People"
                  ? "expanded"
                  : "collapsed"
                : listState
            }
            itemSelect={(pkId, dataIdx, dataLength) => handlePeopleSelection(pkId, dataIdx, dataLength)}
            itemAdd={(dataLength) => handlePeopleAdd(dataLength)}
            data={
              people &&
              people.map((p) => ({
                id: p.dataSource,
                displayText: `${p.category}`,
                avatarIcon: GetPeopleAvatar(p.category, true),
                avatarText: GetPeopleAvatar(p.category, false),
                state: GetItemState(p.reviewDate, p.expiryDate),
                selectedItem: selectedItem,
                reviewDate: p.reviewDate,
                expiryDate: p.expiryDate,
                lastUpdated: p.timeStamp,
                pkId: p.pkId,
                hint: generateHint(p.name, p.notes),
              }))
            }
          />
          <AAXDMDataListItem
            title="Location"
            itemIcon="Location"
            itemState={
              expandedItem && expandedItem.length > 0
                ? expandedItem === "Location"
                  ? "expanded"
                  : "collapsed"
                : listState
            }
            itemSelect={(pkId, dataIdx, dataLength) => handleLocationSelection(pkId, dataIdx, dataLength)}
            itemAdd={(dataLength) => handleLocationAdd(dataLength)}
            data={
              locations &&
              locations.map((l) => ({
                id: l.dataSource,
                displayText: `${l.category}`,
                avatarIcon: GetLocationAvatar(l.category, true),
                avatarText: GetLocationAvatar(l.category, false),
                state: GetItemState(l.reviewDate, l.expiryDate),
                selectedItem: selectedItem,
                reviewDate: l.reviewDate,
                expiryDate: l.expiryDate,
                lastUpdated: l.timeStamp,
                pkId: l.pkId,
                hint: generateHint(l.name, l.notes),
              }))
            }
          />
          {template && template !== "Fire" && (
            <AAXDMDataListItem
              title="Miscellaneous"
              itemIcon="Miscellaneous"
              editable={template === "Ambulance"}
              colour="brown"
              itemState={
                expandedItem && expandedItem.length > 0
                  ? expandedItem === "Miscellaneous"
                    ? "expanded"
                    : "collapsed"
                  : listState
              }
              itemSelect={(pkId, dataIdx, dataLength) => handleMiscellaneousSelection(pkId, dataIdx, dataLength)}
              itemAdd={(dataLength) => handleMiscellaneousAdd(dataLength)}
              data={
                miscellaneous &&
                miscellaneous.map((m) => ({
                  id: m.dataSource,
                  displayText: `${m.category}`,
                  avatarIcon: GetMiscellaneousAvatar(m.category, true),
                  avatarText: GetMiscellaneousAvatar(m.category, false),
                  state: GetItemState(m.reviewDate, m.expiryDate),
                  selectedItem: selectedItem,
                  reviewDate: m.reviewDate,
                  expiryDate: m.expiryDate,
                  lastUpdated: m.timeStamp,
                  pkId: m.pkId,
                  hint: generateHint(m.name, m.notes),
                }))
              }
            />
          )}
        </Box>
      </List>
    </Fragment>
  );
};

export default XDMDataListForm;
