/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets User Avatar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook                 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Removed colour unless cursor is hovering.
//    003   01.06.21 Sean Flook         WI39345 Highlight the icon when the cursor is hovering over it.
//    004   08.09.21 Joel Benford       WI39518 Handle click on the Login/UserAdmin button
//    005   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React, { useState, useContext } from "react";
import { LoginRoute, UserAdminRoute } from "../PageRouting";
import { actionIconStyle } from "../utils/AAStyles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Tooltip, Grid } from "@mui/material";
import { SymphonyUserContext } from "../context/symphonyUserContext";
import { GetCurrentUserFirstName } from "../utils/helperUtils";
/* #endregion imports */


function ActionIconStyle() {

    return {
        // color: "#535353",
        color: "#2A6EBB",
        "&:hover": {
            color: "#7f7f7f",
        },
    };
}


function AAUserAvatar(props) {

  
  const [iconHover, setIconHover] = useState(false);
  const { logoff, currentUser } = useContext(SymphonyUserContext);

  const handleMouseEnter = () => {
    setIconHover(true);
  };

  const handleMouseLeave = () => {
    setIconHover(false);
  };

  const handleClick = () => {
    props.handleClick( currentUser ? UserAdminRoute : LoginRoute);
  };

  const tooltipTitle = currentUser ? `Admin ${GetCurrentUserFirstName(currentUser)}` : "login";

    return (
            <Grid item xs onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                <Grid container direction="row" alignItems="center" justifyContent="flex-start" aria-label="Login" size="medium">
                    <Grid item sx={{ marginLeft: 1, marginRight: 1 }}>
                        <Tooltip title={tooltipTitle} arrow placement="right">
                            <AccountCircleIcon
                                fontSize="large" sx={ actionIconStyle }
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        );
}

export default AAUserAvatar;
