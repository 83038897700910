//#region header */
/**************************************************************************************************
//
//  Description: Web site version that will be displayed in the GUI
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.08.22 Joel Benford       WI39945 Initial Revision.
//    002   05.09.22 Joel Benford       WI40273/40277 Rationalise config, search fixes
//    003   28.09.22 Joel Benford       WI39322 change hint to base off description
//    004   29.09.22 Joel Benford       WI40265 host help within GUI deployement
//    005   09.12.22 Joel Benford       WI39945 get version info from API
//    006   12.12.22 Joel Benford       WI40264 node package upgrades
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

export const guiVersionBuild = "5.0.0.0 beta 4 of 26 Feb 2024";
