/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Read Only component
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   19.08.22 Joel Benford       WI40077 Change styling formReadOnly to formReadOnlyText
//    005   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { formRowStyle } from "../utils/AAStyles";

/* #endregion imports */

AAReadOnlyControl.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function AAReadOnlyControl(props) {
  return (
    <Grid container justifyContent="flex-start" alignItems="center" sx={formRowStyle}>
      <Grid item xs={4}>
        <Typography variant="body2" align="left" color="textPrimary">
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="body1"
          align="left"
          color="textPrimary"
          sx={{
            pl: "8px",
            pt: "2px",
            pb: "2px",
          }}
        >
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AAReadOnlyControl;
