//#region header */
/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

var currentConfig = {
  api: "",
  securityApi: "",
  maxRecords: 0,
  template: "",
  authority: 0,
};

const getConfigInfo = () => {
  if (
    !currentConfig ||
    !currentConfig.symphonySecurityApi ||
    currentConfig.symphonySecurityApi.length === 0
  )
  {
    //console.log("DEBUG getting .env");
    const symphonySecurityApi = process.env.REACT_APP_SYMPHONY_SECURITY_API;
  }
};

export function authBearerHeader(token, jsonType = true) {
  // return auth header with jwt if user is logged in and request is to the api url
  //const token = auth?.token;
  const isLoggedIn = !!token;

  let header = {};
  if (jsonType) header["Content-Type"] = "application/json";

  if (isLoggedIn) header["Authorization"] = `Bearer ${token}`;

  return header;
}

export function GetSecurityApiSite(urlController) {
  const symphonySecurityApi = process.env.REACT_APP_SYMPHONY_SECURITY_API;
  const SecurityApiUrl = `${symphonySecurityApi}${urlController}`;
  return SecurityApiUrl;
}


export async function GetPostUserLogin() {
  const url = await GetSecurityApiSite("/api/v2/Authority/Login");
  return {
    url: url,
    type: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  };
}

export async function GetPostAuthenticateUserLogin() {
    const url = await GetSecurityApiSite("/api/v2/Authority/Authenticate");
    return {
        url: url,
        type: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
    };
}


export async function GetWhoAmI() {
  const url = await GetSecurityApiSite("/api/v2/Authority/WhoAmI");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  };
}

export async function GetUpdateMyPassword() {
  const url = await GetSecurityApiSite("/api/v2/Authority/UpdateMyPassword");
  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

/*
export async function GetGetUsers() {
  const url = await GetSecurityApiSite("/api/v2/User/User");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetPostUser() {
  const url = await GetSecurityApiSite("/api/v2/User/User");
  return {
    url: url,
    type: "POST",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetPutUser() {
  const url = await GetSecurityApiSite("/api/v2/User/User");
  return {
    url: url,
    type: "PUT",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetDeleteUser() {
  const url = await GetSecurityApiSite("/api/v2/User/User");
  return {
    url: url,
    type: "DELETE",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}

export async function GetGetUser() {
  const url = await GetSecurityApiSite("/api/v2/User/User");
  return {
    url: url,
    type: "GET",
    mode: "cors",
    headers: [{ "Content-Type": "application/json" }],
  };
}
*/
