//#region header */
/**************************************************************************************************
//
//  Description: User Administration Page
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook                 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState,  useContext, useEffect } from "react";
import { Box, Grid, Typography, Snackbar, Alert,  Button } from "@mui/material";
import { SymphonyUserContext } from "../context/symphonyUserContext";
import { homePageStyle } from "../utils/AAStyles";
import  ResetPasswordDialog  from "../dialogs/ResetPasswordDialog";
import * as securityApi from "../services/symphonySecurityApi";

/* #endregion imports */

const UserAdminpage = (props) => {

    const { currentUser, currentToken, logoff } = useContext(SymphonyUserContext);

    const [showPasswordDlg, setShowPasswordDlg] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const [errorAlert, setErrorAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();


    const HandleResetPassword = async (newPassword) => {

        setErrorMsg(null);
        const callResult = await securityApi.authority.updateMyPassword(currentToken, newPassword, setErrorMsg, logoff);

        if (callResult && !errorMsg) {
            setSuccessMsg("Password Reset");
            setShowPasswordDlg(false);
        }
    };

    const TextGridRow = ({field, value}) => {
        return (
            <Grid
                container
                direction="row"
                spacing={1}
            >
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={3}>
                    <Typography variant="body1">{field}</Typography>    
                </Grid>
                <Grid item>
                    <Typography variant="body1">{value}</Typography>
                </Grid>
            </Grid>
        );
    };

    const ButtonRow = ({ userid, clickHandler }) => {
        return (
            <Grid
                container
                direction="row"
                spacing={2}
            >
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={4}>
                    <Grid container direction="row">
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" onClick={clickHandler}>Reset Password</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" onClick={logoff}>Logoff</Button>
                        </Grid>
                    </Grid>
                </Grid>    

            </Grid>
        );
    };

    const Handler_ResetPassword = (val) => {
        setShowPasswordDlg(val);
    };

    useEffect(() => {
        if(errorMsg) setErrorAlert(true)
    },[errorMsg]);

    useEffect(() => {
        if(successMsg) setSuccessAlert(true)
    },[successMsg]);


    return currentUser ?  (
        <Box sx={homePageStyle}>

        <Snackbar open={errorAlert} autoHideDuration={20000} onClose={() => setErrorAlert(false)}>
            <Alert onClose={()=>setErrorAlert(false)} severity="error" sx={{ width: '100%' }}>
                {errorMsg}
            </Alert>
        </Snackbar>

        <Snackbar open={successAlert} autoHideDuration={5000} onClose={() => setSuccessAlert(false)}>
            <Alert onClose={()=>setSuccessAlert(false)} severity="success" sx={{ width: '100%' }}>
                {successMsg}
            </Alert>
        </Snackbar>

            <Grid container direction="column" justifyContent="flex-start" alignItems="left" spacing={2}>
                <TextGridRow field="" value="" />
                <TextGridRow field="Name" value={currentUser.firstName + " " + currentUser.lastName} />
                <TextGridRow field="Organisation" value={currentUser.organisation} />
                <TextGridRow field="Email" value={currentUser.email} />
                <TextGridRow field="Created" value={currentUser.created} />
                <TextGridRow field="Last Updated" value={currentUser.lastUpdated} />

                <ButtonRow userid={currentUser.id} clickHandler = {() => Handler_ResetPassword(true)} />
                
                <Grid item xs>
                {
                    showPasswordDlg ? <ResetPasswordDialog closeDialogHandler={() => Handler_ResetPassword(false)} continueHandler={async(pwd)=> await HandleResetPassword(pwd)} continueError={errorText} /> : <React.Fragment/> 
                }
                </Grid>
                </Grid>
        </Box>
     ) :
     (
        <Typography variant="h3">Loading User Data</Typography>
    );
};

export default UserAdminpage;
