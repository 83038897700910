/* #region header */
/**************************************************************************************************
//
//  Description: Metadata Context
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.08.22 Joel Benford        WI39945 Initial Revision.
//    002   09.12.22 Joel Benford        WI39445 New fields in default data
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import { guiVersionBuild } from "../configuration/AAVersionInfo";
/* #endregion imports */

export const MetadataContext = React.createContext();
MetadataContext.displayName = "MetadataContext";

export const metadataInitial = {
  versionInfo: {
    guiVersion: guiVersionBuild,
    xdmapiVer: "",
    xdmdbVer: "",
    searchIndexDllVer: "",
    searchLibDllVer: "",
    xdmIndexMeta: {
      dbserver: "",
      dbname: "",
      builton: "",
      elasticnugetversion: "",
    },
    addressIndexMeta: {
      epochversion: "",
      bluelightversion: "",
      imanageversion: null,
      dbserver: "",
      dbname: "",
      builton: "",
      elasticnugetversion: "",
    },
  },
};

export default MetadataContext;
