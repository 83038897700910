/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar Header component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   14.05.21 Sean Flook         WI39345 Added className.
//    003   01.06.21 Sean Flook         WI39345 Removed code that was not required.
//    004   10.06.21 Sean Flook         WI39345 Added onClick event to return to the home screen.
//    005   17.06.21 Sean Flook         WI39345 Added FormattedAddress to property change.
//    006   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    007   12.12.23 Sean Flook                 Changes required for upgrade of react-router-dom to v6.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropertyContext from "../context/propertyContext";
import { HomeRoute } from "../PageRouting";
import Grid from "@mui/material/Grid";

/* #endregion imports */

const AANavHeader = () => {
  const navigate = useNavigate();

  const propertyContext = useContext(PropertyContext);

  const handleClickEvent = () => {
    propertyContext.onPropertyChange(0, "", "", "", 0, 0);
    navigate(HomeRoute);
  };

  return (
    <Grid item xs={12}>
      <img
        src="/images/aligned_assets_logo.svg"
        alt="Aligned Assets"
        width="32"
        height="32"
        onClick={handleClickEvent}
      />
    </Grid>
  );
};

export default AANavHeader;
