//#region header */
/**************************************************************************************************
//
//  Description: Main Launch Page for the App
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   20.05.21 Sean Flook         WI39345 Changed to use the light ESRI theme.
//    003   12.12.23 Sean Flook                 Changes required for React 18.
//    004   12.12.23 Sean Flook                 Changes required for upgrade of react-router-dom to v6.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React from "react";
import {SymphonyUserContextProvider} from "./context/symphonyUserContext";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@arcgis/core/assets/esri/themes/light/main.css";
/* #endregion imports */

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
        <BrowserRouter>
        <SymphonyUserContextProvider>
                <App />
        </SymphonyUserContextProvider>
        </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
