/* #region header */
/**************************************************************************************************
//
//  Description: Features related to getting metadata
//
//  Copyright:    � 2022 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   08.08.22 Joel Benford        WI39945 Initial version, get version info from API
//    002   09.12.22 Joel Benford        WI39945 Implement API fetch in place of dummy data
//#endregion Version 1.0.0.0 changes
//**************************************************************************************************/
/* #endregion header */

import { guiVersionBuild } from "../configuration/AAVersionInfo";
import { GetGetVersion, authBearerHeader } from "../configuration/AAConfig";

// apiDetails - as returned by AAConfig
// token - e.g. (useContext(CurrentUserContext)).currentUser.token
// errorMessage - string to report on exception
// params - array of string/stringable to go in REST URL
// body - object to attach to call, will be serialized
const loggedInCall = async (apiDetails, token, errorMessage, params, body) => {
  const finalUrl = apiDetails.url + (params ? params.map((p) => `${p}/`) : "");
  let call = {
    headers: authBearerHeader(token, true),
    mode: apiDetails.mode,
    crossDomain: true,
    method: apiDetails.type,
  };
  if (body) call = { ...call, body: JSON.stringify(body) };
  const apiResult = await fetch(finalUrl, call)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())
    .then(
      (result) => {
        return result ? result : {};
      },
      (error) => {
        console.log(errorMessage, error);
      }
    );
  return apiResult;
};

const fetchVersionInfo = async (token) => {
  const apiInfo = await loggedInCall(
    await GetGetVersion(),
    token,
    "ERROR getting Version information from API",
    null,
    null
  );
  return { ...apiInfo, guiVersion: guiVersionBuild };
};

const getVersionInfo = async (token, existingMetadata) => {
  const newVersionInfo = await fetchVersionInfo(token);
  const newMetadata = {
    ...existingMetadata,
    versionInfo: newVersionInfo,
  };
  return newMetadata;
};

//functions relating to metadata.versionInfo
export const version = { updateFromApi: getVersionInfo };
