//#region header */
/**************************************************************************************************
//
//  Description: Delete Confirmation Dialog
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   11.06.21 Sean Flook         WI39345 Changes after UX review.
//    003   17.06.21 Sean Flook         WI39345 Set width of dialog.
//    004   18.06.21 Sean Flook         WI39345 Corrected spelling.
//    005   28.06.21 Sean Flook         WI39345 Set the correct colour for the delete button.
//    006   01.07.21 Sean Flook         WI39345 Increased font size.
//    007   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

/* #endregion imports */

function ConfirmDeleteDialog({ onClose, open, title, subtitle, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="xs" open={isOpen} aria-labelledby="confirmation-dialog-title">
      <DialogTitle id="confirmation-dialog-title">{title ? title : "Delete record?"}</DialogTitle>
      <DialogContent>
        {subtitle && (
          <Typography variant="h6" sx={{ pb: "8px" }}>
            {subtitle}
          </Typography>
        )}
        <Typography variant="body1">{content ? content : "Are you sure you wish to delete this record?"}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleOk}
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#C4051C",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          autoFocus
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          onClick={handleCancel}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDeleteDialog;
