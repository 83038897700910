/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   12.05.21 Sean Flook         WI39345 Removed unused button and removed colour unless cursor is hovering.
//    003   14.05.21 Sean Flook         WI39345 Adjusted the grid justify and alignItems.
//    004   01.06.21 Sean Flook         WI39345 Removed home button and placed the user button at the bottom.
//    005   10.06.21 Sean Flook         WI39345 Pass the history to AANavHeader.
//    006   13.09.21 Sean Flook         WI39345 Changes to grid to get the icons displaying correctly.
//    007   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    008   12.12.23 Sean Flook                 Changes required for upgrade of react-router-dom to v6.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import { useNavigate } from "react-router-dom";

import { Drawer, Grid } from "@mui/material";

import AANavHeader from "./AANavHeader";
import AAUserAvatar from "./AAUserAvatar";

import { navBarWidth } from "../utils/AAStyles";
/* #endregion imports */

const AANavContent = () => {
  const navigate = useNavigate();

  const handleItemClick = (routeName) => {
    navigate(routeName);
  };

  return (
    <Drawer
      sx={{
        width: navBarWidth,
      }}
      variant="permanent"
      anchor="left"
    >
      <Grid
        sx={{
          paddingTop: "8px",
          width: navBarWidth,
          height: "98vh",
        }}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="column" alignItems="center" justifyContent="flex-start">
            <AANavHeader />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" justifyContent="flex-end">
            <AAUserAvatar
              handleClick={(routeName) => {
                handleItemClick(routeName);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

AANavContent.propTypes = {};
AANavContent.defaultProps = {};

export default AANavContent;
