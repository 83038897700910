/* #region header */
/**************************************************************************************************
//
//  Description: Dashboard tables form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   07.05.21 Peter Bryden               Added in updatedData count
//    003   10.05.21 Sean Flook         WI39345 Added variable size count avatars.
//    004   25.05.21 Sean Flook         WI39345 Set the colour for the selected tab text and indicator.
//    005   17.06.21 Sean Flook         WI39345 Use rounded avatars rather than circles so they do not look strange if we have to expand them.
//    006   01.07.21 Sean Flook         WI39345 Bold numbers in avatar.
//    007   07.12.23 Sean Flook                 Migrated MUI to latest version.
//    008   12.12.23 Sean Flook                 Fix MUI for React 18
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography, Avatar, Box } from "@mui/material";
import DashboardExpired from "../components/tables/DashboardExpired";
import DashboardReviewOverdue from "../components/tables/DashboardReviewOverdue";
import DashboardUpdatedRecently from "../components/tables/DashboardUpdatedRecently";
import { red, grey } from "@mui/material/colors";
import { useTheme } from "@mui/system";
/* #endregion imports */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function DashboardTablesForm({ expiredData, reviewData, updatedData }) {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  // console.log("DEBUG Dashboard Table Expired", expiredData);
  // console.log("DEBUG Dashboard Table Review", reviewData);
  // console.log("DEBUG Dashboard Table Updated", updatedData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStyle = (isActive) => {
    return isActive ? { color: "#2A6EBB" } : null;
  };

  const getAvatarStyle = (data) => {
    if (!data || data.length === 0)
      return {
        width: theme.spacing(2),
        height: theme.spacing(2),
        backgroundColor: "transparent",
      };
    else if (data.length < 10)
      return {
        width: theme.spacing(2),
        height: theme.spacing(2),
        color: theme.palette.getContrastText(red[600]),
        backgroundColor: red[600],
        paddingTop: theme.spacing(0.35),
        borderRadius: "18px",
        fontFamily: "Open Sans",
      };
    else if (data.length < 100)
      return {
        width: theme.spacing(3),
        height: theme.spacing(2),
        color: theme.palette.getContrastText(red[600]),
        backgroundColor: red[600],
        paddingTop: theme.spacing(0.35),
        borderRadius: "18px",
        fontFamily: "Open Sans",
      };
    else
      return {
        width: theme.spacing(4),
        height: theme.spacing(2),
        color: theme.palette.getContrastText(red[600]),
        backgroundColor: red[600],
        paddingTop: theme.spacing(0.35),
        borderRadius: "18px",
        fontFamily: "Open Sans",
      };
  };

  return (
    <Box
      sx={{
        marginTop: "32px",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: grey[400],
        height: "45vh",
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: { background: "#2A6EBB" },
          }}
          textColor="primary"
          variant="standard"
          selectionFollowsFocus
          aria-label="dashboard tables"
        >
          <Tab
            label="Expired"
            style={getStyle(value === 0)}
            icon={
              <Avatar variant="rounded" sx={getAvatarStyle(expiredData)}>
                <Typography variant="caption">
                  <strong>{expiredData && expiredData.length}</strong>
                </Typography>
              </Avatar>
            }
            {...a11yProps(0)}
          />
          <Tab
            label="Review overdue"
            style={getStyle(value === 1)}
            icon={
              <Avatar variant="rounded" sx={getAvatarStyle(reviewData)}>
                <Typography variant="caption">
                  <strong>{reviewData && reviewData.length}</strong>
                </Typography>
              </Avatar>
            }
            {...a11yProps(1)}
          />
          <Tab
            label="Updated recently"
            style={getStyle(value === 2)}
            icon={
              <Avatar sx={getAvatarStyle(null)}>
                <Typography variant="caption" />
              </Avatar>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DashboardExpired tableData={expiredData ? reviewData : []} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DashboardReviewOverdue tableData={reviewData ? reviewData : []} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DashboardUpdatedRecently tableData={updatedData} />
      </TabPanel>
    </Box>
  );
}

export default DashboardTablesForm;
