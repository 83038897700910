//#region header */
/**************************************************************************************************
//
//  Description: Proxy the SymphonySecurity API, group endpoints by controller
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   12.08.21 Joel Benford        WI39518 Initial Revision.
//	  002	09.03.22 Peter Bryden		WI40103	Added in Symphony API Security Authentication
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import {
  GetPostUserLogin,
  GetUpdateMyPassword,
    GetWhoAmI,
    authBearerHeader,
    GetPostAuthenticateUserLogin
} from "../configuration/AASecurity_Config";
/* #endregion imports */


// returns an object including a token which must be passed in heades to other endpoints
const login = async (auditname, password) => {
  const apiDetails = await GetPostUserLogin();
    const loginResult = await fetch(apiDetails.url, {
        headers: apiDetails.headers,
    mode: apiDetails.mode,
    crossDomain: true,
    method: apiDetails.type,
    body: JSON.stringify({ auditname: auditname, password: password }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())
    .then(
      (result) => {
        return result ? result : {};
      },
      (error) => {
        console.log("ERROR posting credentials to Login", error);
      }
    );
  return loginResult;
};


const MFAAuthenticate = async (authorizeId, code) => {
    const apiDetails = await GetPostAuthenticateUserLogin();
    const loginResult = await fetch(`${apiDetails.url}/${authorizeId}/${code}`, {
        headers: apiDetails.headers,
        mode: apiDetails.mode,
        crossDomain: true,
        method: apiDetails.type,
    })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then(
            (result) => {
                return result ? result : {};
            },
            (error) => {
                console.log("ERROR posting to MFAAuthenticate", error);
            }
        );
    return loginResult;
};


//Get Error from response
const GetError = async (res) => {
    const responseText = await res.text().then((err) => { return (err ? err : ""); });
    try {
        const jsonText = JSON.parse(responseText);
        return JSON.stringify(jsonText.errors ?? jsonText);
    }
    catch (err) {
        return responseText;
    }
};


// apiDetails - as returned by AAConfig
// token - e.g. (useContext(CurrentUserContext)).currentUser.token
// errorMessage - string to report on exception
// params - array of string/stringable to go in REST URL
// body - object to attach to call, will be serialized
const loggedInCall = async (apiDetails, token, errorHandler, logoffHandler, params, body) => {
    const finalUrl = apiDetails.url + (params ? params.map((p) => `${p}/`) : "");

  let call = {
    headers: authBearerHeader(token,true),
    mode: apiDetails.mode,
    crossDomain: true,
    method: apiDetails.type,
  };
  if (body) call = { ...call, body: JSON.stringify(body) };
  const apiResult = await fetch(finalUrl, call)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
                      const contentType = res.headers.get("content-type");
                      return (contentType && contentType.indexOf("application/json") !== -1) ? res.json() : res.Text();
    })
    .then(
        (result) => (  result ? result : {} )
        //(error) =>  { console.log(errorMessage, error);
    ).catch(async(error) => {

      const errText = await GetError(error);
      //console.log("errText ", errText);

      switch (error.status) {
          case 400:
              errorHandler && errorHandler(errText.length > 0 ? `An error occurred : ${errText}` : `An error occurred while process this request`);
              break;

          case 401:
              logoffHandler(true);
              errorHandler && errorHandler("Your credentials have expired, please login again.");
              break;

          default:
              errorHandler && errorHandler(`error (${error.status}) has occurred, please report to support.`);
              break;
      }
      //return error;
      return null;
  });;
  
  return apiResult;
};

// AUTHORITY CONTROLLER
const whoAmI = async(token, errorHandler, logoffHandler) =>
  await loggedInCall(
    await GetWhoAmI(),
    token,
    errorHandler,
    logoffHandler,
    null,
    null
  );

const updateMyPassword = async(token, newPassword, errorHandler, logoffHandler) =>
  await loggedInCall(
    await GetUpdateMyPassword(),
    token,
    errorHandler,
    logoffHandler,
    null,
    {
      password: newPassword,
    }
  );

export const authority = {
  login,
  whoAmI,
  updateMyPassword,
   MFAAuthenticate
};

/*
//USER CONTROLLER
const getUsers = async (token) =>
  await loggedInCall(
    await GetGetUsers(),
    token,
    "ERROR getting Users",
    null,
    null
  );

const postUser = async (token, user) =>
  await loggedInCall(
    await GetPostUser(),
    token,
    "ERROR posting user",
    null,
    user
  );

const putUser = async (token, user) =>
  await loggedInCall(
    await GetPutUser(),
    token,
    "ERROR putting user",
    null,
    user
  );

const deleteUser = async (token, user) =>
  await loggedInCall(
    await GetDeleteUser(),
    token,
    "ERROR deleting user",
    null,
    user
  );

const getUser = async (token, id) =>
  await loggedInCall(
    await GetGetUser(),
    token,
    "ERROR getting user",
    `${id}`,
    null
  );

export const user = { getUser, getUsers, postUser, putUser, deleteUser };
*/
