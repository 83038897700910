/* #region header */
/**************************************************************************************************
//
//  Description: Handle Page Routing
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook          WI39345 Initial Revision.
//    002   21.06.21 Joel Benford        WI39518 Add login page
//    003   28.06.21 Sean Flook          WI39345 Corrected typo.
//    004   12.12.23 Sean Flook                 Changes required for upgrade of react-router-dom to v6.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SymphonyUserContext } from "./context/symphonyUserContext";

import Homepage from "./pages/Homepage";
import Xdmpage from "./pages/Xdmpage";
import UserAdminpage from "./pages/UserAdminpage";
import LoginPageWithMFA from "./pages/LoginPageWithMFA";
/* #endregion imports */

export const HomeRoute = "/";
export const XdmRoute = "/xdm";
export const UserAdminRoute = "/useradmin";
export const LoginRoute = "/login";
export const LogoutRoute = "/logout";

/*
export const CurrentRoute = () => {
  const currentRoute = window.location.pathname;
  // console.log("DEBUG CurrentRoute", currentRoute);

  if (currentRoute.startsWith(XdmRoute)) return XdmRoute;

  return HomeRoute;
};

const ProtectedRoute = ({
    path,
    element: Component,
    render,
    currentUser,
    ...Rest
}) => {
    return (
        <Route
            {...Rest}
            render={(props) => {
                if (!currentUser) return <Navigate to={LoginRoute} />;
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};
*/

const PageRouting = () => {
    const { currentUser, currentToken } = useContext(SymphonyUserContext);

    //console.log("DEBUG PageRouting currentToken",  currentUser ? currentUser.auditName : null,  currentToken, window.location.pathname);

    return (
        <Routes>
            <Route exact path={LogoutRoute} render={() => <Navigate to={LoginRoute} />} />
            <Route exact path={LoginRoute} element={<LoginPageWithMFA />} />
            <Route
                exact
                path={`${XdmRoute}/:uprn`}
                element={!currentToken ? <Navigate to={LoginRoute} replace /> : <Xdmpage />}
            />
            <Route exact path={UserAdminRoute} element={!currentToken ? <Navigate to={LoginRoute} replacenp/> :  <UserAdminpage />}  />
            <Route path="*" exact element={!currentToken ? <Navigate to={LoginRoute} replace /> : <Homepage />} />
        </Routes>
    );

};

export default PageRouting;
