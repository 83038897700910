//#region header */
/**************************************************************************************************
//
//  Description: All the styling used by the app
//
//  Copyright:    © 2021-22 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   10.05.21 Sean Flook         WI39345 Tweaks required for the dashboard.
//    004   12.05.21 Sean Flook         WI39345 Various styling changes.
//    005   14.05.21 Sean Flook         WI39345 Various styling changes.
//    006   19.05.21 Sean Flook         WI39345 Added styling for list menu box.
//    007   19.05.21 Sean Flook         WI39345 Changed some of the styling for the list items.
//    008   20.05.21 Sean Flook         WI39345 various styling changes.
//    009   01.06.21 Sean Flook         WI39345 Further styling changes.
//    010   02.06.21 Sean Flook         WI39345 Added styling for help drawer.
//    011   08.06.21 Sean Flook         WI39345 Added further styling for help drawer.
//    012   16.06.21 Sean Flook         WI39345 Various changes after UX review.
//    013   17.06.21 Sean Flook         WI39345 Changes required to correctly style the search.
//    014   17.06.21 Sean Flook         WI39345 Updated grey styling.
//    015   28.06.21 Sean Flook         WI39345 Set colour for buttons. Set radius on number avatars.
//    016   01.07.21 Sean Flook         WI39345 Correctly handle the hover colours. Changes required for numbers in avatars.
//    017   19.08.22 Joel Benford       WI40077 Add formReadOnlyText for text (i.e. not readonly date pickers etc)
//    018   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import { grey } from "@mui/material/colors";

/* #endregion imports */

export const navBarWidth = 70;
export const helpBarWidth = 400;

export const appBarTitleStyle = { color: "#333333", flexGrow: 1, display: "block" };

export const helpRowStyle = { pt: "4px" };

export const helpIconStyle = { color: "#535353", display: "inline-flex" };

export const helpTextStyle = { color: "#535353" };

export const actionIconStyle = {
  color: "#535353",
  "&:hover": {
    color: "#2A6EBB",
  },
};

export const actionLabelStyle = { pl: "2px", pr: "4px", color: "#535353" };

export const actionAlertStyle = { backgroundColor: "#2A6EBB" };

export const menuTextStyle = { pl: "4px" };

export const formToolbarTextStyle = { mt: "1.2px" };

export const formRowStyle = {
  pt: "4px",
  pl: "4px",
  pr: "8px",
  color: "#535353",
  display: "flex",
  justifyContent: "flex-start",
};

export const formReadOnlyStyle = { pl: "8px", pt: "7px", pb: "7px" };

export const xdmDataFormStyle = { overflowY: "auto", width: "100%", height: "84vh" };

export const listTypeIconStyle = { minWidth: 32 };

export const loginStyle = { margin: "auto", mt: "150px", width: "400px" };

export const homePageStyle = { width: `calc(98vw - ${navBarWidth}px)`, mt: "8px" };

export const dashboardIconStyle = { color: grey[400] };

export const listDateStyle = { width: 120 };

export const dialogButtonStyle = {
  color: "#2A6EBB",
  "&:hover": {
    backgroundColor: "#00AEEF",
    color: "#FFFFFF",
  },
};

/**
 * The styling used for tooltips.
 */
export const tooltipStyle = { fontSize: "14px" };

/**
 * Returns the styling for a date input component.
 *
 * @param {boolean} hasError True if the component is displaying an error; otherwise false.
 * @return {object} The styling to use for the form date input.
 */
export function FormDateInputStyle(hasError) {
  if (hasError)
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      mt: "6px",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C !important",
      },
    };
  else
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      mt: "6px",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#F8F9FA !important",
      },
    };
}

/**
 * Returns the styling for an input component.
 *
 * @param {boolean} hasError True if the component is displaying an error; otherwise false.
 * @return {object} The styling to use for the form input.
 */
export function FormInputStyle(hasError) {
  if (hasError)
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      pl: "4px",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C !important",
      },
    };
  else
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#F8F9FA !important",
      },
    };
}

/**
 * Returns the styling for a select input component.
 *
 * @param {boolean} hasError True if the component is displaying an error; otherwise false.
 * @return {object} The styling to use for the form select input.
 */
export function FormSelectInputStyle(hasError) {
  if (hasError)
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      display: "inline-flex",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C !important",
      },
    };
  else
    return {
      fontFamily: "Nunito sans",
      fontSize: "15px",
      color: "#333333",
      display: "inline-flex",
      "&$outlinedInputFocused": {
        borderColor: "#2A6EBB !important",
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#F8F9FA !important",
      },
    };
}

/**
 * Return the styling for the clear search icon
 *
 * @param {string} text The current search string
 * @return {object} The styling to be used for the clear search icon.
 */
export function ClearSearchIconStyle(text) {
  if (text && text.length > 0)
    return {
      color: "#535353",
      "&:hover": {
        color: "#2A6EBB",
      },
    };
  else
    return {
      display: "none",
    };
}

/**
 * The styling used for a blue button.
 */
export const blueButtonStyle = {
  color: "#FFFFFF",
  backgroundColor: "#2A6EBB",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#00AEEF",
    color: "#FFFFFF",
  },
};

/**
 * The styling used for a white button.
 */
export const whiteButtonStyle = {
  color: "#2A6EBB",
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#00AEEF",
    color: "#FFFFFF",
  },
};

export function DialogFormRowStyle(hasError) {
  //const theme = useTheme();
  return  (hasError)
  ?
     {
      paddingTop: 1,
      paddingLeft: 1,
      paddingRight: 2,
      //color: theme.palette.text.secondary,
      backgroundColor: "rgba(196, 5, 28, 0.1)",
      display: "flex",
      justifyContent: "flex-start",
    }
  :
    {
      paddingTop: 1,
      paddingLeft: 1,
      paddingRight: 2,
      //color: theme.palette.text.secondary,
      display: "flex",
      justifyContent: "flex-start",
    };    
}

export function DialogFormInputStyle(hasError) {
  return (hasError)
    ? {
      backgroundColor: "#333333",
      "&$outlinedInputFocused": {
        borderColor: `#333333 !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C",
        // borderColor: `${red[500]} !important`,
      },
      "&.MuiFormHelperText-root": {
        textAlign: "right",
        marginRight: 0,
      }
     }
  :
    {
      //backgroundColor: "#ff0000",
      backgroundColor: "#333333",
      "&$outlinedInputFocused": {
        borderColor: `#333333 !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#e1e1e1 !important",
      },
      "& .MuiFormHelperText-contained": {
        textAlign: "right",
        marginRight: 0
      } 
    };
}

