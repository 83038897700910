import React, { useEffect, useState } from "react";
import { authority } from "../services/symphonySecurityApi";


/* #endregion imports */

const SymphonyUserContext = React.createContext();
SymphonyUserContext.displayName = "SymphonyUserContext";

const SymphonyUserContextProvider = ({ props, children }) => {

    const tokenStorageName = "curToken";

    const GetLocalStorage = (key) => {
        const val = localStorage.getItem(tokenStorageName);
        return (!val || (val === 'null')) ? null : val;
    }

    const [loginDetails, setLoginDetails] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [userError, setUserError] = useState(null);
    const [useAuthenticate, setUseAuthenticate] = useState(true);
    const [currentToken, setCurrentToken] = useState(GetLocalStorage(tokenStorageName));
    const [currentTokenExpiry, setCurrentTokenExpiry] = useState();
    const [authenticateId, setAuthenticateId] = useState(null);
    const [showAuthenticate, setShowAuthenticate] = useState((authenticateId !== null) && !currentToken);
    const [showLogin, setShowLogin] = useState(!currentToken);


    const showLogging = (msg) => {
        const bShowLogging = false;

        if(bShowLogging)
            console.log(`symphonyUserContext::${msg}`);
    };


    // Local Storage: setting & getting data
    useEffect(() => {
        const storedtokenData = GetLocalStorage(tokenStorageName);

        if (currentToken && (storedtokenData !== currentToken)) {
            showLogging(`setting local storage token to ${currentToken}`);
            localStorage.setItem(tokenStorageName, currentToken)
        }

        if (!currentToken && storedtokenData) {
            showLogging(`setting current token from local storage token`);
            setShowLogin(false);
            setCurrentToken(storedtokenData);
        }

        const GetUserInfo = async () => {
            showLogging(`getting user info for token ${currentToken}`);
            const userInfo = await authority.whoAmI(currentToken,setUserError, logoff);
            if (userInfo)
            {
                if (userInfo.active && !userInfo.isDeleted) {
                    setUserError(null);
                    setAuthenticateId(null);
                    setCurrentUser(userInfo);
                }
                else {
                    showLogging(`*** no user info after call to whoAmI for token ***`);
                    setCurrentUser(null);
                    //userContext.onUserChange(null);
                    setUserError(!userInfo.active ?
                        "You are not an active user on this system." :
                        "This user has been deleted.");
                }
            }
            else
            {
                setUserError("Unable to get user information.");
            }
        }

        if (currentToken && !currentUser) {
            showLogging(`getting user info for token ${currentToken}`);
             GetUserInfo();
        }

    }, [currentToken, currentUser]);


    useEffect(() => {

        showLogging(`loginDetails changed to ${loginDetails}`);

        if (loginDetails && loginDetails.token) {
            setCurrentToken(loginDetails.token);
            setCurrentTokenExpiry(loginDetails.expiry);
        }
        /*
        else {
            setCurrentUser(null);
            setCurrentToken(null);
            setCurrentTokenExpiry(null);
        }
        */
    }, [loginDetails])


    useEffect(() => {
        showLogging(`currentUser changed to ${currentUser}`);
        setShowLogin(!currentUser)

        function checkAuthenticate()
        {   
            if (useAuthenticate)
                setShowAuthenticate((authenticateId !== null) && !currentUser)
        }

        checkAuthenticate();

    }, [currentUser])

    useEffect(() => {
        showLogging(`authenticateId changed to ${authenticateId}, useAuthenticate is ${useAuthenticate}`);

        function checkAuthenticate()
        {
            if (useAuthenticate) {
                //console.log("userContext ::useeffect authenicateId", authenticateId, showLogin);
                setShowLogin(!(authenticateId !== null));
                setShowAuthenticate(!!authenticateId);
            }
        }

        checkAuthenticate();

    }, [authenticateId])


    useEffect(() => {

        showLogging(`userError changed to ${userError}`);

        if (userError && userError.length > 0) {
            setLoginDetails(null);
            localStorage.setItem(tokenStorageName, null);
            setCurrentUser(null);
            if (useAuthenticate) {
                setAuthenticateId(null);
                setShowAuthenticate(false);
            }
            setShowLogin(true);
        }
    }, [userError, useAuthenticate])


    function logoff(reload) {
        //console.log(`loggoff called`);
        setLoginDetails(null);
        localStorage.setItem(tokenStorageName, null);

        if (reload)
            window.location.reload();
    }

    function updateLoginDetails(newLogin) {
        showLogging(`updatelogindetails : `, newLogin);
        setLoginDetails(newLogin);
    }

    function updateCurrentUser(newUser) {
        showLogging(`updatecurrentuser : `, newUser);
        setCurrentUser(newUser);
    }

    function updateUserError(error) {
        showLogging(`updateusererror : `, error);
        setUserError(error);
    }

    function updateShowLogin(newshowLogin) {
        showLogging(`updateshowlogin : `, newshowLogin);
        setShowLogin(newshowLogin);
    }

    function updateAuthenticateId(newAuthenticateId) {
        showLogging(`updateauthenticateid : `, newAuthenticateId);
        setAuthenticateId(newAuthenticateId);
    }

    function updateShowAuthenticate(newshowAuthenticate) {
        showLogging(`updateshowauthenticate : `, newshowAuthenticate);
        setShowAuthenticate(newshowAuthenticate);
    }

    function updateUseAuthenticate(newUseAuthenticate) {
        showLogging(`updateuseauthenticate : `, newUseAuthenticate);
        setUseAuthenticate(newUseAuthenticate);
    }


    return (
        <SymphonyUserContext.Provider value={{ currentToken, currentTokenExpiry, currentUser, useAuthenticate, authenticateId, showLogin, showAuthenticate,  userError, logoff, setShowAuthenticate, updateUserError, updateLoginDetails, updateCurrentUser, updateShowLogin, updateAuthenticateId, updateShowAuthenticate, updateUseAuthenticate }}>
            {children}
        </SymphonyUserContext.Provider>
    )
}

export { SymphonyUserContextProvider, SymphonyUserContext }
