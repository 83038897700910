/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Text component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.04.21 Sean Flook         WI39345 Initial Revision.
//    002   05.05.21 Sean Flook         WI39345 Tweaks to the UI after design review meeting.
//    003   14.05.21 Sean Flook         WI39345 Updated className.
//    004   18.05.21 Sean Flook         WI39345 Use the value directly.
//    005   20.05.21 Sean Flook         WI39345 Display a tooltip if required.
//    006   25.05.21 Sean Flook         WI39345 Include required field text if required to tooltip.
//    007   27.05.21 Sean Flook         WI39345 Alter display for multiline controls.
//    008   01.06.21 Sean Flook         WI39345 For multiline controls increase size of control depending on amount of text in control.
//    009   08.06.21 Sean Flook         WI39345 Changed read-only version to a label and altered colour of outline.
//    011   10.06.21 Sean Flook         WI39345 Only display characters left at the bottom when have a multiline component.
//    012   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Tooltip, TextField } from "@mui/material";
import { FormInputStyle, formRowStyle } from "../utils/AAStyles";

/* #endregion imports */

AATextControl.propTypes = {
  label: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number.isRequired,
  minLines: PropTypes.number,
  maxLines: PropTypes.number,
  helperText: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

AATextControl.defaultProps = {
  isEditable: false,
  isRequired: false,
  minLines: 1,
  maxLines: 1,
  errorText: "",
};

function AATextControl({
  label,
  isEditable,
  isRequired,
  maxLength,
  minLines,
  maxLines,
  helperText,
  value,
  errorText,
  id,
  onChange,
}) {
  const multiline = useRef(minLines > 1);
  const hasError = useRef(false);

  const GetControlRowSize = () => {
    if (multiline.current) return 12;
    else return 8;
  };

  const handleChangeEvent = (event) => {
    if (onChange) onChange(event);
  };

  useEffect(() => {
    hasError.current = errorText !== "" && errorText !== null;
  }, [errorText]);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems={multiline.current ? "flex-start" : "center"}
      sx={formRowStyle}
    >
      <Grid item xs={4}>
        <Typography
          id={`aa-text-label-${label.replaceAll(" ", "-")}`}
          variant="body2"
          color={hasError.current ? "error" : isEditable ? "textPrimary" : "initial"}
          align="left"
        >
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      </Grid>
      <Grid item xs={GetControlRowSize()}>
        {helperText && helperText.length > 0 ? (
          <Tooltip title={isRequired ? helperText + " This is a required field." : helperText} arrow placement="right">
            <TextField
              id={`aa-text-textfield-${label.replaceAll(" ", "-")}`}
              sx={FormInputStyle(hasError.current)}
              error={hasError.current}
              fullWidth
              rows={minLines}
              maxRows={maxLines}
              multiline={multiline.current}
              disabled={!isEditable}
              required={isRequired}
              variant="outlined"
              margin="dense"
              size="small"
              helperText={hasError.current && errorText}
              value={value}
              onChange={handleChangeEvent}
            />
          </Tooltip>
        ) : (
          <TextField
            id={`aa-text-textfield-${label.replaceAll(" ", "-")}`}
            sx={FormInputStyle(hasError.current)}
            error={hasError.current}
            fullWidth
            rows={minLines}
            maxRows={maxLines}
            multiline={multiline.current}
            disabled={!isEditable}
            required={isRequired}
            variant="outlined"
            margin="dense"
            size="small"
            helperText={hasError.current && errorText}
            value={value}
            onChange={handleChangeEvent}
          />
        )}
      </Grid>
      {multiline.current && value && value.length > 0 ? (
        <Grid item xs={12}>
          <Typography id={`aa-text-${label.replaceAll(" ", "-")}-characters-left`} variant="body2" align="right">
            {maxLength - value.length} characters left
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

export default AATextControl;
