/* #region header */
/**************************************************************************************************
//
//  Description: Dashboard Chart form
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   05.05.21 Sean Flook         WI39345 Initial Revision.
//    002   14.05.21 Sean Flook         WI39345 Changed grid justify and alignItem and className.
//    003   20.05.21 Sean Flook         WI39345 Removed overdue and added miscellaneous chart.
//    004   07.12.23 Sean Flook                 Migrated MUI to latest version.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import Grid from "@mui/material/Grid";
import AADoughnutChart from "../components/AADoughnutChart";
/* #endregion imports */

function DashboardChartsForm({ allData, riskData, peopleData, locationData, miscellaneousData }) {
   //console.log("DEBUG CountCharts All", allData, allData.length);
   //console.log("DEBUG CountCharts Risk", riskData, riskData.length);
   //console.log("DEBUG CountCharts People", peopleData, peopleData.length);
   //console.log("DEBUG CountCharts Location", locationData,locationData.length);
   //console.log("DEBUG CountCharts Miscellaneous", miscellaneousData, miscellaneousData.length);

  return (
    <Grid
      container
      sx={{ pt: "8px" }}
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      {allData && allData.length > 0 && (
        <AADoughnutChart chartData={allData} title="All types" label="type" value="count" />
      )}
      {riskData && riskData.length > 0 && (
        <AADoughnutChart chartData={riskData} title="Risk" label="category" value="count" />
      )}
      {peopleData && peopleData.length > 0 && (
        <AADoughnutChart chartData={peopleData} title="People" label="category" value="count" />
      )}
      {locationData && locationData.length > 0 && (
        <AADoughnutChart chartData={locationData} title="Location" label="category" value="count" />
      )}
      {miscellaneousData && miscellaneousData.length > 0 && (
        <AADoughnutChart chartData={miscellaneousData} title="Miscellaneous" label="category" value="count" />
      )}
    </Grid>
  );
}

export default DashboardChartsForm;
